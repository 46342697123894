import React, { useEffect, useState } from "react";
import {
  FaUsers,
  FaChalkboardUser,
  FaHandsHoldingChild,
  FaMoneyBillTransfer,
} from "react-icons/fa6";
import "./DashboardMain.css";
import { doughnutChartData } from "../../services/data";
import Chart from "../chart/Chart";
import Calender from "../calender/Calender";
import { Card, Row, Col, message } from "antd";
import CustomCard from "../card/CustomCard";
import NoticeBoard from "../notice-board/NoticeBoard";
import useFetch from "../../hooks/useFetch";

function DashboardMain() {
  // State for summary data, loading, and error
  const [summaryData, setSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch hook for dashboard data
  const { dataFetched: dashFetchRes, get: fetchDash } = useFetch(
    "/api/admin-dashboard",
    {
      setLoading,
      setError,
    }
  );

  // Dashboard icons and styling
  const dashIcons = [
    {
      icon: <FaUsers />,
      background: "linear-gradient(135deg, #34b3a0, #00796b)", // Teal to deep green
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
    {
      icon: <FaChalkboardUser />,
      background: "linear-gradient(135deg, #1E90FF, #0047AB)", // Bright blue to navy blue
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
    {
      icon: <FaHandsHoldingChild />,
      background: "linear-gradient(135deg, #FF6F61, #D84315)", // Coral to deep orange
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
    {
      icon: <FaMoneyBillTransfer />,
      background: "linear-gradient(135deg, #4CAF50, #388E3C)", // Green gradient
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
  ];

  // Fetch dashboard data on component mount
  useEffect(() => {
    fetchDash();
  }, []);

  // Process fetched data and map with icons
  useEffect(() => {
    if (dashFetchRes && dashFetchRes.length > 0) {
      const mappedData = dashFetchRes.map((data, i) => ({
        ...data,
        ...dashIcons[i],
      }));
      setSummaryData(mappedData);
    }
  }, [dashFetchRes, dashIcons]);

  // Display error message if any
  useEffect(() => {
    if (error) {
      message.error("Failed to load dashboard data. Please try again.");
    }
  }, [error]);

  return (
    <div id="DashboardMain">
      <section className="main-content">
        <ul>
          <li>
            <a href="btn">Home</a>
          </li>
        </ul>

        <Row gutter={16}>
          {summaryData?.map((school, index) => {
            const { name, amount, icon, background } = school;
            return (
              <Col
                key={index}
                xs={24}
                sm={12}
                lg={6}
                style={{ marginBottom: 10 }}
              >
                <Card
                  className="custom-card"
                  style={{ background: background }}
                  title={name}
                  bordered={false}
                  hoverable
                  loading={loading}
                >
                  <CustomCard amount={amount} icon={icon} />
                </Card>
              </Col>
            );
          })}
        </Row>

        <Row style={{ width: "100%" }} gutter={[16, 16]}>
          <Col sm={24} lg={12}>
            <Chart chartData={doughnutChartData} />
          </Col>
          <Col sm={24} lg={12}>
            <Calender />
          </Col>
          <Col sm={24} lg={12}>
            <NoticeBoard />
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default DashboardMain;
