import React, { useState } from "react";
import { Layout, theme } from "antd";
import TeacherRoutes from "../../../router/routes/TeacherRoute";
import "./Teacher.css";
import Headers from "../../../components/header/Header";
import TeacherSider from "../../../components/sidebar/teacher-sidebar/TeacherSider";
const { Content } = Layout;
const items1 = ["1", "2", "3"].map((key) => ({
  key,
  label: `nav ${key}`,
}));

const Teacher = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout>
      <TeacherSider />
      <Layout>
        <Headers />

        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: "95vh",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            marginTop: "30px",
          }}
        >
          <TeacherRoutes />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Teacher;
