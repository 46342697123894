import React, { useState, useEffect, useMemo } from "react";
import {
  Form,
  Input,
  Select,
  Popconfirm,
  Button,
  Row,
  message,
  Col,
  Card,
  Modal,
} from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import useDelete from "../../../hooks/useDelete";
import usePatch from "../../../hooks/usePatch";
import DynamicTable from "../../tables/DynamicTable";
import StaffRegForm from "../../forms/staff-reg-form/StaffRegForm";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";
import { convertToDisplayDate } from "../../../utils/helper";

const { Option } = Select;

function StaffRegistration() {
  const [form] = Form.useForm();
  const [modalClosed, setModalClosed] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [editingStaff, setEditingStaff] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [current, setCurrent] = useState(0);

  // Post operation states
  const [staffCreatedRes, setStaffCreatedRes] = useState(false);
  const [staffCreateLoading, setStaffCreateLoading] = useState(false);
  const [staffCreateError, setStaffCreateError] = useState(false);

  // Patch operation states
  const [staffModifyRes, setStaffModifyRes] = useState(false);
  const [staffModifyLoading, setStaffModifyLoading] = useState(false);
  const [staffModifyError, setStaffModifyError] = useState(false);

  // Delete operation states
  const [staffDeleteLoading, setStaffDeleteLoading] = useState(false);
  const [staffDeleteError, setStaffDeleteError] = useState(false);
  const [staffDeleteRes, setStaffDeleteRes] = useState(false);

  // Fetch operation states
  const [staffFetchLoading, setStaffFetchLoading] = useState(false);
  const [staffFetchError, setStaffFetchError] = useState(false);
  const [staffData, setStaffData] = useState([]);

  // Fetch staff data
  const { get: fetchStaffs } = useFetch("/api/user", {
    setLoading: setStaffFetchLoading,
    setError: setStaffFetchError,
    setData: setStaffData,
  });

  // Create staff
  const { post: createStaff, reset: resetCreateStaffPost } = usePost(
    "/api/user",
    {
      setLoading: setStaffCreateLoading,
      setError: setStaffCreateError,
      setData: setStaffCreatedRes,
    }
  );

  // Modify staff
  const { patch: modifyStaff, reset: resetStaffModify } = usePatch(
    `/api/user/${editingStaff?.id}`,
    {
      setLoading: setStaffModifyLoading,
      setError: setStaffModifyError,
      setData: setStaffModifyRes,
    }
  );

  // Remove staff
  const { remove: removeStaff, reset: resetStaffRemove } = useDelete(
    `/api/user`,
    {
      setLoading: setStaffDeleteLoading,
      setError: setStaffDeleteError,
      setData: setStaffDeleteRes,
    }
  );

  useEffect(() => {
    fetchStaffs(); // Fetch staff data on component mount
  }, []);

  // Handle search input change
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const excludedCols = [
    "ID",
    "FULL NAME",
    "EMAIL",
    "EDUC LEVEL",
    "ROLE",
    "SEX",
    "REGION",
    "SUB CITY",
    "PHONE",
    "CREATED",
    "UPDATED",
    "CREATED BY",
  ];
  // Generate dynamic columns
  const generateColumns = (data) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "INFO",
        key: "info",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record["FULL NAME"].toUpperCase()}
            </div>

            <div className="tableSubCols details">ID: {record["ID"]}</div>
            <div className="tableSubCols details">Sex: {record["SEX"]}</div>
            <div className="tableSubCols details">
              Edu Level: {record["EDUC LEVEL"]}
            </div>
          </> //0911429007
        ),
      },
      {
        title: "PHONE",
        key: "phone",
        render: (text, record) => (
          <>
            <div className="tableSubCols details">Phone: {record["PHONE"]}</div>
            <div className="tableSubCols details">Email: {record["EMAIL"]}</div>
            <div className="tableSubCols details">Role: {record["ROLE"]}</div>
          </>
        ),
      },

      {
        title: "REGION",
        key: "region",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["REGION"]}</div>
            <div className="tableSubCols details">
              Sub City: {record["SUB CITY"]}
            </div>
          </>
        ),
      },
      {
        title: "CREATED",
        key: "created",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {convertToDisplayDate(record["CREATED"])}
            </div>
            <div className="tableSubCols details">
              Updated: {convertToDisplayDate(record["UPDATED"])}
            </div>
            <div className="tableSubCols details">
              Created by: {record["CREATED BY"]}
            </div>
          </>
        ),
      },
    ];

    const sampleItem = data[0];
    const keys = Object.keys(sampleItem).filter(
      (key) => !excludedCols.includes(key)
    );

    const dynamicColumns = keys.map((key) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1),
      key,
      dataIndex: key,
    }));

    dynamicColumns.push({
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <span>
          <Button type="link" onClick={() => handleEdit(record)}>
            <EditOutlined />
          </Button>
        </span>
      ),
    });

    return [...commonColumns, ...dynamicColumns];
  };

  const customColumns = useMemo(() => generateColumns(staffData), [staffData]);

  const filteredData = useMemo(() => {
    return staffData?.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [staffData, searchText, selectedColumn]);

  // Handle create staff
  const handleCreate = async () => {
    try {
      const values = form.getFieldValue();
      await form.validateFields();
      await createStaff(values);
    } catch (error) {
      console.log(error);
      message.error("Failed to create User");
    }
  };

  // Handle edit staff
  const handleEdit = (value) => {
    setEditingStaff(value);
    setIsEditModalVisible(true);
  };

  // Handle update staff
  const handleUpdate = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldValue();
      await modifyStaff(values);
    } catch (error) {
      console.error(error);
      message.error("Failed to update user");
    }
  };

  useEffect(() => {
    if (staffCreatedRes?.message && staffCreatedRes?.status)
      message?.[staffCreatedRes.status](staffCreatedRes.message);
    if (staffCreateError?.message) message.error(staffCreateError.message);
    if (staffCreatedRes?.message === "created") {
      fetchStaffs();
      setModalClosed(true);
    }
    resetCreateStaffPost();
  }, [staffCreatedRes, staffCreateError]);

  useEffect(() => {
    if (staffModifyRes?.message && staffModifyRes?.status)
      message?.[staffModifyRes.status](staffModifyRes.message);
    if (staffModifyError?.message) message.error(staffModifyError.message);
    if (staffModifyRes?.message === "updated") {
      fetchStaffs();
      setIsEditModalVisible(false);
    }
    resetStaffModify();
  }, [staffModifyRes, staffModifyError]);

  useEffect(() => {
    if (staffDeleteRes?.message && staffDeleteRes?.status)
      message?.[staffDeleteRes.status](staffDeleteRes.message);
    if (staffDeleteError?.message) message.error(staffDeleteError.message);
    if (staffDeleteRes?.message === "deleted") {
      fetchStaffs();
    }
    resetStaffRemove();
  }, [staffDeleteRes, staffDeleteError]);

  return (
    <div id="StaffRegistration">
      <div className="header">
        <h2>Staff Registration</h2>
        <Breadcrumb />
      </div>
      <Card title="Add New Staff" bordered={false} style={{ margin: "20px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          {contextHolder}
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
              options={excludedCols.map((col) => ({
                key: col,
                value: col,
              }))}
            />
          </Col>
          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setModalClosed(false);
                  setEditingStaff(false);
                }}
              >
                Add Staff
              </Button>
            </div>
          </Col>
        </Row>
        <DynamicTable
          data={filteredData}
          loading={staffCreateLoading}
          customColumns={customColumns}
          scroll={{ x: 1000 }}
        />
        {editingStaff && (
          <Modal
            title="Edit Staff"
            open={isEditModalVisible}
            onOk={handleUpdate}
            onCancel={() => setIsEditModalVisible(false)}
          >
            <StaffRegForm
              form={form}
              current={current}
              setCurrent={setCurrent}
              editingStaff={editingStaff}
            />
          </Modal>
        )}
        <Modal
          title="Add New Staff"
          open={!modalClosed}
          onOk={handleCreate}
          onCancel={() => setModalClosed(true)}
        >
          <StaffRegForm
            form={form}
            current={current}
            setCurrent={setCurrent}
            editingStaff={editingStaff}
          />
        </Modal>
      </Card>
    </div>
  );
}

export default StaffRegistration;
