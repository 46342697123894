import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs"; // or moment.js
import {
  Card,
  Row,
  Col,
  Select,
  Button,
  Modal,
  List,
  Form,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import AnnouncementForm from "../../forms/announcement-form/AnnouncementForm";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import { grade, section } from "../../../services/data";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import "./Announcement.css";
const { Option } = Select;

const sampleAnnouncements = [
  {
    id: 1,
    receiver: "teacher",
    message: "Parent-Teacher meeting on Friday.",
    grade: "1",
    section: "A",
  },
  { id: 2, message: "Sports Day next Wednesday.", grade: "2", section: "B" },
];

const Announcement = () => {
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("A");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [announcements, setAnnouncements] = useState(sampleAnnouncements);
  //post
  const [announcementCreatedRes, setAnnouncementCreatedRes] = useState(false);
  const [announcementCreateLoading, setAnnouncementCreateLoading] =
    useState(false);
  const [announcementCreateError, setAnnouncementCreateError] = useState(false);
  // Refactored useFetch for teacher Announcement
  const [announcement, setAnnouncement] = useState([]);
  const [announcementLoading, setAnnouncementLoading] = useState(false);
  const [announcementFetchError, setAnnouncementFetchError] = useState(null);

  const { get: fetchAnnouncement } = useFetch("/api/announcement", {
    setLoading: setAnnouncementLoading,
    setError: setAnnouncementFetchError,
    setData: setAnnouncement,
  });

  const { post: createAnnouncement, reset: resetCreateAnnouncementPost } =
    usePost("/api/announcement", {
      setLoading: setAnnouncementCreateLoading,
      setError: setAnnouncementCreateError,
      setData: setAnnouncementCreatedRes,
    });

  useEffect(() => {
    fetchAnnouncement();
  }, []);

  useEffect(() => {
    if (announcementCreatedRes?.message)
      message[announcementCreatedRes?.status || "success"](
        announcementCreatedRes?.message
      );

    if (announcementCreatedRes?.status === "success") {
      setIsModalVisible(false);

      form.resetFields();
      resetCreateAnnouncementPost();
      fetchAnnouncement();
    }
  }, [announcementCreatedRes]);

  const handleAddOk = async () => {
    const values = await addForm.validateFields();
    await createAnnouncement({
      ...values,
    });

    console.log("here are the values:", values);
  };

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);

  const handleSendAnnouncement = (announcementMessage, receiver) => {
    const newAnnouncement = {
      id: announcements.length + 1,
      message: announcementMessage,
      grade: selectedGrade,
      section: selectedSection,
      receive: receiver,
    };
    setAnnouncements([...announcements, newAnnouncement]);
  };

  const filteredAnnouncements = useMemo(() => {
    return announcements.filter(
      (announcement) =>
        announcement.grade === selectedGrade &&
        announcement.section === selectedSection
    );
  }, [announcements, selectedGrade, selectedSection]);
  useEffect(() => {
    fetchGradeDivisions();
  }, []);
  return (
    <div id="Announcement">
      <h2>Announcement Page</h2>
      <Card title="Announcements" bordered={false} style={{ margin: "20px" }}>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Select
              defaultValue={selectedGrade}
              onChange={handleGradeChange}
              style={{ width: 200 }}
            >
              {gradeDivisions?.map((gra, index) => (
                <Option key={index} value={gra.name}>
                  {gra.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              placeholder="Select Section"
              defaultValue={selectedSection}
              onChange={handleSectionChange}
              style={{ width: 200 }}
            >
              {section.map((sec, index) => (
                <Option key={index} value={sec}>
                  {sec}
                </Option>
              ))}
            </Select>
          </Col>
          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setIsModalVisible(true)}
              >
                Add Announcement
              </Button>
            </div>
          </Col>
        </Row>
        <List
          itemLayout="horizontal"
          dataSource={announcement}
          renderItem={(announcement) => (
            <List.Item>
              <List.Item.Meta
                title={`${announcement.receiver} Announcement`}
                description={
                  <div className="announcement-item">
                    <span>{announcement.announcementMessage}</span>
                    <span className="announcement-date">
                      {dayjs(announcement.createdAt).format(
                        "DD MMM YYYY, h:mm A"
                      )}
                    </span>
                  </div>
                }
              />
            </List.Item>
          )}
        />

        <Modal
          title="Send Announcement"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          // footer={null}
          onOk={handleAddOk}
        >
          <AnnouncementForm
            form={addForm}
            selectedSection={selectedSection}
            selectedGrade={selectedGrade}
          />
        </Modal>
      </Card>
    </div>
  );
};

export default Announcement;
