import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import { message, Table, Select, Card, Row, Col, Form, Modal } from "antd";
import { grade, section, subject } from "../../../services/data";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import "./Assessments.css";

const { Option } = Select;
const EditableContext = React.createContext(null);

const sampleData = [
  {
    id: "St-16-O-A-H#Bc264",
    name: "Ahafiz Ali Yusuf",
    sex: "male",
    grade: "Nursery",
    section: "A",
    subject: "Math",
    W1: 5,
    T1: 8,
    Mid_1: 12,
    Mid_2: 12,
    Final: 19,
    Total70: 58,
    Total100: 72,
  },
  // Add more sample data if needed
];

const generateColumns = () => [
  {
    title: "Id",
    key: "id",
    render: (text, record) => (
      <>
        <div className="tableSubCols heading">{record.name.toUpperCase()}</div>
        <div className="tableSubCols details">ID: {record.id}</div>
        <div className="tableSubCols details">Sex: {record.sex}</div>
      </>
    ),
  },
  {
    title: "W.1 (5%)",
    key: "W1",
    dataIndex: "W1",
    editable: true,
  },
  {
    title: "T.1 (8%)",
    key: "T1",
    dataIndex: "T1",
    editable: true,
  },
  {
    title: "Mid 1 (15%)",
    key: "Mid_1",
    dataIndex: "Mid_1",
    editable: true,
  },
  {
    title: "Mid 2 (20%)",
    key: "Mid_2",
    dataIndex: "Mid_2",
    editable: true,
  },
  {
    title: "Final (50%)",
    key: "Final",
    dataIndex: "Final",
    editable: true,
  },
  // Add more columns if needed
];

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  dataSource,
  setDataSource,
  ...restProps
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const inputRef = useRef(null);

  const openModal = () => {
    setIsModalVisible(true);
    setModalData(
      dataSource.map((item) => ({
        id: item.id,
        name: item.name,
        value: item[dataIndex],
      }))
    );
    setCurrentIndex(0);
  };

  const handleModalOk = () => {
    const updatedData = modalData.map((item, index) => ({
      ...dataSource[index],
      [dataIndex]: item.value,
    }));
    setDataSource(updatedData);
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleValueChange = (index, newValue) => {
    const newModalData = [...modalData];
    newModalData[index].value = newValue;
    setModalData(newModalData);
  };

  const handleArrowKey = (direction) => {
    let newIndex = currentIndex + direction;
    if (newIndex < 0) newIndex = modalData.length - 1;
    if (newIndex >= modalData.length) newIndex = 0;
    setCurrentIndex(newIndex);
    setTimeout(() => inputRef.current?.focus(), 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "ArrowDown") {
      handleArrowKey(1);
      event.preventDefault();
    }
  };

  let childNode = children;
  if (editable) {
    childNode = (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingInlineEnd: 24 }}
        onClick={openModal}
      >
        {children}
      </div>
    );
  }

  return (
    <td {...restProps}>
      {childNode}
      <Modal
        title={`Edit ${title}`}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        width={600}
      >
        <div className="custom-table">
          <table>
            <thead>
              <tr>
                <th>Student ID</th>
                <th>Student Name</th>
                <th>{title}</th>
              </tr>
            </thead>
            <tbody>
              {modalData.map((item, index) => (
                <tr
                  key={item.id}
                  className={index === currentIndex ? "selected-row" : ""}
                >
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <input
                      ref={index === currentIndex ? inputRef : null}
                      type="text"
                      value={item.value}
                      onChange={(e) => handleValueChange(index, e.target.value)}
                      onFocus={() => setCurrentIndex(index)}
                      onKeyDown={handleKeyDown}
                      autoFocus={index === currentIndex}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </td>
  );
};

const MarkList = () => {
  const [dataSource, setDataSource] = useState(sampleData);
  const [selectedGrade, setSelectedGrade] = useState("LKG");
  const [selectedSection, setSelectedSection] = useState("A");
  const [selectedSubject, setSelectedSubject] = useState("Math");

  // Fetch marks
  const [marks, setMarks] = useState([]);
  const [markLoading, setMarkLoading] = useState(false);
  const [fetchMarkError, setFetchMarkError] = useState(null);

  const { get: fetchMark } = useFetch("/api/markList", {
    setLoading: setMarkLoading,
    setError: setFetchMarkError,
    setData: setMarks,
  });

  useEffect(() => {
    // fetchMark();
  }, [selectedGrade, selectedSection, selectedSubject]);

  const handleGradeChange = (val) => setSelectedGrade(val);
  const handleSectionChange = (val) => setSelectedSection(val);
  const handleSubjectChange = (val) => setSelectedSubject(val);

  const handleSave = (row, changedField) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setDataSource(newData);
  };

  const filteredData = useMemo(() => {
    return marks.filter((item) => {
      return (
        (!selectedGrade || item.grade === selectedGrade) &&
        (!selectedSection || item.section === selectedSection) &&
        (!selectedSubject || item.subject === selectedSubject)
      );
    });
  }, [marks, selectedGrade, selectedSection, selectedSubject]);

  return (
    <div id="MarkList">
      <h2>Mark List</h2>
      <Card title="Mark List" bordered={false}>
        <Row gutter={16}>
          <Col>
            <Select
              value={selectedGrade}
              onChange={handleGradeChange}
              style={{ width: 200 }}
            >
              {grade.map((gra, index) => (
                <Option key={index} value={gra.val}>
                  {gra.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              value={selectedSection}
              onChange={handleSectionChange}
              style={{ width: 200 }}
            >
              {section.map((sec, index) => (
                <Option key={index} value={sec}>
                  {sec}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              value={selectedSubject}
              onChange={handleSubjectChange}
              style={{ width: 200 }}
            >
              {subject.map((sub, index) => (
                <Option key={index} value={sub}>
                  {sub}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Card>
      <Table
        components={{
          body: {
            row: EditableRow,
            cell: (props) =>
              EditableCell({ ...props, dataSource, setDataSource }),
          },
        }}
        rowClassName={() => "editable-row"}
        bordered
        dataSource={filteredData}
        columns={generateColumns()}
        loading={markLoading}
      />
      {fetchMarkError && <p>Error fetching marks: {fetchMarkError.message}</p>}
    </div>
  );
};

export default MarkList;
