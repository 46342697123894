import React, { useEffect, useState, useMemo } from "react";
import { Button, Select, message, Modal, Form, Input, Row, Col } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";
import DynamicTable from "../../tables/DynamicTable";
import StudRegForm from "../../forms/stud-reg-form/StudRegForm";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import StudentTable from "../../tables/students-table/StudentsTable";
import "./StudentRegistration.css";
import { grade } from "../../../services/data";
import { convertToDisplayDate } from "../../../utils/helper";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
const { Option } = Select;
const StudentRegistration = () => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [modalClosed, setModalClosed] = useState(true);
  const [current, setCurrent] = useState(0);
  const [modalOkBtnEnabled, setModalOkBtnEnabled] = useState(true);
  const [selectedGrade, setSelectedGrade] = useState("");
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [form] = Form.useForm();

  // State variables for student creation
  const [studCreateLoading, setStudCreateLoading] = useState(false);
  const [studCreateError, setStudCreateError] = useState(null);
  const [studCreatedRes, setStudCreatedRes] = useState(null);

  // State variables for fetching students
  const [fetchStudLoading, setFetchStudLoading] = useState(false);
  const [fetchStudError, setFetchStudError] = useState(null);
  const [studentData, setStudentData] = useState([]);

  // API hook for creating a student
  const { post: createStud, reset: resetCreateStud } = usePost("/api/student", {
    setLoading: setStudCreateLoading,
    setError: setStudCreateError,
    setData: setStudCreatedRes,
  });

  // API hooks for fetching students
  const { get: fetchStudents } = useFetch("/api/student", {
    setLoading: setFetchStudLoading,
    setError: setFetchStudError,
    setData: setStudentData,
  });

  console.log("fetched Students", studentData);

  // Fetch students when selectedGrade changes
  useEffect(() => {
    fetchStudents({ grade: selectedGrade });
  }, [selectedGrade]);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  // Control form step for modal
  useEffect(() => {
    setModalOkBtnEnabled(current + 1 < 3);
  }, [current]);

  // Handle responses from creating student
  useEffect(() => {
    if (studCreatedRes?.message && studCreatedRes?.status)
      message?.[studCreatedRes.status](studCreatedRes.message);

    if (studCreatedRes?.message === "created") {
      fetchStudents({ grade: selectedGrade });
      setModalClosed(true);
    }
    resetCreateStud();
  }, [studCreatedRes]);
  useEffect(() => {
    if (studCreateError)
      message.error(studCreateError?.message || "System error");

    resetCreateStud();
  }, [studCreateError]);

  useEffect(() => {
    if (gradeDivisions?.length) setSelectedGrade(gradeDivisions?.[0]?.name);
  }, [gradeDivisions]);

  const handleCreate = async () => {
    try {
      if (current + 1 >= 3) {
        console.log("values", form.getFieldValue());

        await form.validateFields();
        const values = form.getFieldValue();
        createStud(values);
        console.log("valuesss", values);
      }
    } catch (error) {
      message.error("Validation Error");
      console.error("Validation Failed:", error);
    }
  };

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  return (
    <div id="StudentRegistration">
      <div className="header">
        <h2>Students</h2>
        <Breadcrumb />
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Select
              placeholder="Grade"
              value={selectedGrade}
              onChange={(val) => setSelectedGrade(val)}
              style={{ width: 200 }}
              allowClear
              // options={gradeOptions}
            >
              {gradeDivisions.map((gra) => (
                <Option key={gra.name} value={gra.name}>
                  {gra.name}
                </Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
            />
          </Col>
          <Col flex="auto">
            <div style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setModalClosed(false)}
              >
                Add Student
              </Button>
            </div>
          </Col>
        </Row>
      </div>

      <StudentTable
        studentData={studentData}
        studentLoading={fetchStudLoading}
      />

      <Modal
        title="Register Student"
        centered
        open={!modalClosed}
        onCancel={() => setModalClosed(true)}
        onOk={handleCreate}
        confirmLoading={studCreateLoading}
        okButtonProps={{ disabled: modalOkBtnEnabled }}
        width={1250}
      >
        <StudRegForm
          form={form}
          current={current}
          setCurrent={setCurrent}
          modalOkBtnEnabled={modalOkBtnEnabled}
          setModalOkBtnEnabled={setModalOkBtnEnabled}
        />
      </Modal>
    </div>
  );
};

export default StudentRegistration;
