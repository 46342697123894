import React, { useState, useEffect } from "react";
import { grade } from "../../../services/data";
import SubjectForm from "../../forms/subject-form/SubjectForm";
import DynamicTable from "../../tables/DynamicTable";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import useDelete from "../../../hooks/useDelete";
import { Button, Select, Card, message, Modal, Form } from "antd";
import "./Subject.css";

const { Option } = Select;

const Subject = () => {
  const [form] = Form.useForm();

  const [subjCreatedRes, setSubjCreatedRes] = useState(null);
  const [subjCreateLoading, setSubjCreateLoading] = useState(false);
  const [subjCreateError, setSubjCreateError] = useState(false);

  const [subjModifyRes, setSubjModifyRes] = useState(null);
  const [subjModifyLoading, setSubjModifyLoading] = useState(false);
  const [subjModifyError, setSubjModifyError] = useState(false);

  const [subjDeleteLoading, setSubjDeleteLoading] = useState(false);
  const [subjDeleteError, setSubjDeleteError] = useState(false);
  const [subjDeleteRes, setSubjDeleteRes] = useState(false);

  const [selectedGrade, setSelectedGrade] = useState("");
  const [subjEditValue, setSubjEditValue] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);

  // Refactored useFetch for subjects
  const [subjects, setSubjects] = useState([]);
  const [subjLoading, setSubjLoading] = useState(false);
  const [subjFetchError, setSubjFetchError] = useState(null);

  const { get: fetchSubj } = useFetch("/api/subject", {
    setLoading: setSubjLoading,
    setError: setSubjFetchError,
    setData: setSubjects,
  });

  const { post: createSubj, reset: resetCreateSubjPost } = usePost(
    "/api/subject",
    {
      setLoading: setSubjCreateLoading,
      setError: setSubjCreateError,
      setData: setSubjCreatedRes,
    }
  );

  const { patch: modifySubject, reset: resetSubjModify } = usePatch(
    `/api/subject/${subjEditValue?.id}`,
    {
      setLoading: setSubjModifyLoading,
      setError: setSubjModifyError,
      setData: setSubjModifyRes,
    }
  );

  const { remove: removeSubject, reset: resetSubjRemove } = useDelete(
    `/api/subject`,
    {
      setLoading: setSubjDeleteLoading,
      setError: setSubjDeleteError,
      setData: setSubjDeleteRes,
    }
  );

  useEffect(() => {
    fetchSubj();
  }, [selectedGrade]);

  useEffect(() => {
    setSelectedGrade(grade[0].val);
  }, []);

  const handleGradeChange = (val) => {
    setSelectedGrade(val);
  };

  const handleDelete = async (id) => {
    try {
      const [filteredData] = subjects.filter((subject) => subject.id == id);
      await removeSubject(filteredData.id);
    } catch (error) {
      console.error(error);
      message.error("Failed to delete subject");
    }
  };

  const handleEdit = (value) => {
    setSubjEditValue(value);
    setIsEditModalVisible(true);
  };

  const handleUpdate = async () => {
    try {
      form.setFieldValue("grade", selectedGrade);
      await form.validateFields();
      const values = form.getFieldValue();
      await modifySubject(values);
    } catch (error) {
      console.error(error);
      message.error("Failed to update subject");
    }
  };

  const handleCancelUpdate = () => {
    setIsEditModalVisible(false);
  };

  const handleCreate = async () => {
    try {
      form.setFieldValue("grade", selectedGrade);
      await form.validateFields();
      const values = form.getFieldValue();
      await createSubj(values);
    } catch (error) {
      console.error(error);
      message.error("Failed to add subject");
    }
  };

  useEffect(() => {
    if (subjCreatedRes?.message && subjCreatedRes?.status)
      message?.[subjCreatedRes.status](subjCreatedRes.message);
    if (subjCreateError?.message) message.error(subjCreateError.message);
    if (subjCreatedRes?.message === "created") {
      fetchSubj();
      setIsAddModalVisible(false);
    }
    resetCreateSubjPost();
  }, [subjCreatedRes, subjCreateError]);

  useEffect(() => {
    if (subjModifyRes?.message && subjModifyRes?.status)
      message?.[subjModifyRes.status](subjModifyRes.message);
    if (subjModifyError?.message) message.error(subjModifyError.message);
    if (subjModifyRes?.message === "updated") {
      fetchSubj();
      setIsEditModalVisible(false);
    }
    resetSubjModify();
  }, [subjModifyRes, subjModifyError]);

  useEffect(() => {
    if (subjDeleteRes?.message && subjDeleteRes?.status)
      message?.[subjDeleteRes.status](subjDeleteRes.message);
    if (subjDeleteError?.message) message.error(subjDeleteError.message);
    if (subjDeleteRes?.message === "deleted") {
      fetchSubj();
      setIsEditModalVisible(false);
    }
    resetSubjRemove();
  }, [subjDeleteRes, subjDeleteError]);

  const tableActions = [
    {
      label: <EditOutlined />,
      onClick: handleEdit,
    },
    {
      label: <DeleteOutlined />,
      onClick: (record) => handleDelete(record.id),
      confirm: true,
      confirmMessage: "Are you sure you want to delete this subject?",
      danger: true,
    },
  ];

  return (
    <div id="Subject">
      <h2>Subject</h2>
      <Card title="Add New Student" bordered={false} style={{ margin: "20px" }}>
        <div className="subject-page">
          <Select
            value={selectedGrade}
            onChange={handleGradeChange}
            style={{ marginBottom: 20 }}
          >
            {grade.map((gra, index) => (
              <Option key={index} value={gra.val}>
                {gra.name}
              </Option>
            ))}
          </Select>
          <Button
            type="primary"
            onClick={() => setIsAddModalVisible(true)}
            style={{ marginBottom: 20, float: "right" }}
          >
            Add Subject
          </Button>
          <DynamicTable
            data={subjects || []}
            tableActions={tableActions}
            loading={subjLoading}
            scroll={{ x: 700 }}
          />

          {subjEditValue && (
            <Modal
              title="Edit Subject"
              open={isEditModalVisible}
              onOk={handleUpdate}
              onCancel={handleCancelUpdate}
              loading={subjModifyLoading}
            >
              <SubjectForm form={form} subjEditValue={subjEditValue} />
            </Modal>
          )}

          <Modal
            title="Add Subject"
            open={isAddModalVisible}
            onOk={handleCreate}
            loading={subjCreateLoading}
            onCancel={() => setIsAddModalVisible(false)}
          >
            <SubjectForm form={form} subjEditValue={subjEditValue} />
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default Subject;
