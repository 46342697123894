import React, { useEffect, useState } from "react";
import {
  woreda,
  subCitiesByRegion,
  nationalities,
  academicQualifications,
} from "../../services/data";
import { Form, Input, Select, Row, Col, Card, Button, message } from "antd";
import PhoneInput from "../phoneInput/PhoneInput";
import usePost from "../../hooks/usePost";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const { Option } = Select;

function SignUp() {
  const navigate = useNavigate();

  const { authRes, authLoading, authError, getAuth } = useAuth();

  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createdRes, setCreatedRes] = useState(null);

  const [schoolCodeLoading, setSchoolCodeLoading] = useState(false);
  const [schoolCodeError, setSchoolCodeError] = useState(false);
  const [schoolCodeRes, setSchoolCodeRes] = useState(null);

  const [selectedRegion, setSelectedRegion] = useState("Addis Ababa");
  const [matchingSubCity, setMatchingSubCity] = useState([]);
  const [form] = Form.useForm();

  const { post: sendSchoolCode, reset: resetGetCodePost } = usePost(
    "/auth/school/user/get-code",
    {
      setLoading: setSchoolCodeLoading,
      setError: setSchoolCodeError,
      setData: setSchoolCodeRes,
    }
  );

  const { post: createSchool, reset: resetPost } = usePost(
    "/auth/school/user/create",
    {
      setLoading: setCreateLoading,
      setError: setCreateError,
      setData: setCreatedRes,
    }
  );

  const validateFullName = (_, value) => {
    // Trim the input to remove leading and trailing spaces
    const trimmedValue = value ? value.trim() : "";

    // Check if the trimmed value matches the pattern for two or more words
    if (!trimmedValue || /^[a-zA-Z]+(\s[a-zA-Z]+){1,}$/.test(trimmedValue)) {
      return Promise.resolve();
    }

    return Promise.reject(
      new Error(
        "Please enter a full name with at least two words separated by spaces"
      )
    );
  };

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter a password"));
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
      return Promise.reject(
        new Error("Password must include a special character")
      );
    }
    return Promise.resolve();
  };

  const handleGetCode = async () => {
    try {
      await form.validateFields(["phone"]);
      const value = await form.getFieldValue();
      await sendSchoolCode(value);
    } catch (error) {
      console.error(error);
    }
  };

  const onFinish = (values) => {
    console.log("Form values: ", values);
    values.role = "superAdmin";
    createSchool(values);
  };

  useEffect(() => {
    if (createdRes?.status === "success" && createdRes.userToken) {
      localStorage.setItem(`userAccessToken`, createdRes.userToken);
      message[createdRes?.status || "success"](createdRes?.message);
      navigate("/auth/login");
      form.resetFields();
      resetPost();
    }
  }, [createdRes]);

  useEffect(() => {
    console.log("createError >>>>>>>>>>>>>>>>>>>", createError);

    if (createError?.message)
      message[createError?.status || "error"](createError?.message);
    resetPost();
  }, [createError]);

  useEffect(() => {
    getAuth();
  }, []);

  // Handle school code errors
  useEffect(() => {
    if (schoolCodeError) {
      message.error(schoolCodeError.message); // Handle the error
      resetGetCodePost(); // Reset after handling error
    }
  }, [schoolCodeError]);

  // Handle school code success response
  useEffect(() => {
    if (schoolCodeRes?.message) {
      message.success(schoolCodeRes?.message);
      resetGetCodePost();
    }
  }, [schoolCodeRes]);

  useEffect(() => {
    setMatchingSubCity(subCitiesByRegion[selectedRegion]);
    form.setFieldValue("subCity", subCitiesByRegion[selectedRegion]?.[0]);
  }, [selectedRegion]);

  useEffect(() => {
    console.log("-----------------------authRes", authRes);
    if (!authRes) return;

    const data = {
      isSchoolVerified: false,
      isUserVerified: false,
      role: null,
      schoolPaymentStatus: "unpaid",
      schoolStatus: "pending",
      schoolSubscriptionType: "basic",
      schoolUserCreated: true,
    };

    if (
      authRes?.schoolPaymentStatus === "unpaid" &&
      authRes?.schoolUserCreated === true
    ) {
      return navigate("/subscription/payment");
    }

    if (
      authRes?.schoolPaymentStatus === "pending" &&
      authRes?.schoolUserCreated === true
    ) {
      return navigate("/subscription/payment");
    }

    if (
      authRes?.schoolPaymentStatus === "payed" &&
      authRes?.schoolStatus !== "active"
    ) {
      return navigate("/subscription/payment");
    }

    if (
      authRes?.schoolPaymentStatus === "payed" &&
      authRes?.schoolStatus === "active"
    ) {
      return navigate("/auth/login");
    }
  }, [authRes]);

  return (
    <Card
      title="Sign Up"
      style={{ maxWidth: 500, margin: "auto", marginTop: 30 }}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Full Name"
              name="fullName"
              rules={[
                { required: true, message: "Please enter full name" },
                { validator: validateFullName },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <PhoneInput form={form} name="phone" label="Phone" />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Row
            justify="space-between"
            style={{
              paddingRight: "20px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Col span={18}>
              <Form.Item
                label="verification code"
                name="verificationCode"
                rules={[
                  {
                    required: true,
                    message: "Please input the verification code!",
                  },
                ]}
                // hasFeedback
                validateStatus="success"
              >
                <Input.OTP />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button onClick={handleGetCode} loading={schoolCodeLoading}>
                Get
              </Button>
            </Col>
          </Row>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter the email" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select the gender" }]}
            >
              <Select placeholder="Please select gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              name="educationalLevel"
              label="Educational Level"
              rules={[
                {
                  required: true,
                  message: "Please select the educational level",
                },
              ]}
            >
              <Select
                placeholder="Select Educational Level"
                options={academicQualifications}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Nationality"
              name="nationality"
              initialValue="Ethiopia"
              rules={[
                { required: true, message: "Please select your nationality" },
              ]}
            >
              <Select showSearch>
                {nationalities.map((country, index) => (
                  <Option key={index} value={country}>
                    {country}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Region"
              name="region"
              initialValue={selectedRegion}
              rules={[{ required: true, message: "Please select your region" }]}
            >
              <Select onChange={handleRegionChange}>
                {Object.keys(subCitiesByRegion)?.map((sub) => (
                  <Option key={sub} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Sub City"
              name="subCity"
              rules={[
                { required: true, message: "Please select your sub city" },
              ]}
            >
              <Select>
                {matchingSubCity?.map((sub) => (
                  <Option key={sub} value={sub}>
                    {sub}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Woreda"
              name="woreda"
              rules={[{ required: true, message: "Please select your woreda" }]}
            >
              <Select placeholder="Please select your woreda">
                {woreda.map((wor, index) => (
                  <Option key={index} value={wor}>
                    {wor}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter a password" },
                { validator: validatePassword },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12}>
            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                { required: true, message: "Confirm your Password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The two passwords do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button loading={createLoading} type="primary" htmlType="submit">
            Sign Up
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}

export default SignUp;
