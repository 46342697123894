import React, { useEffect, useState } from "react";
import { Form, Input, Select, Button } from "antd";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";

const { Option } = Select;

const TeacherTaskForm = ({ form }) => {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const [selectedGrade, setSelectedGrade] = useState("");

  const onFinish = (values) => {
    const taskDetails = {
      teacherName: values.teacherName,
      grade: values.grade,
      section: values.section,
      subjectName: values.subjectName,
      subjectType: values.subjectType,
      taskType: values.taskType,
    };

    console.log("Task Submitted: ", taskDetails);

    form.resetFields();
  };

  // Handle Grade Selection Change
  const handleGradeChange = (val) => {
    setSelectedGrade(val);
  };

  // Fetch assessments when grade changes
  // useEffect(() => {
  //   if (selectedGrade) fetchAss({ grade: selectedGrade });
  // }, [selectedGrade]);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (gradeDivisions?.length) setSelectedGrade(gradeDivisions?.[0]?.name);
  }, [gradeDivisions]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{
        teacherName: "Tsinat",
        grade: "1",
        section: "A",
        subjectName: "It",
        subjectType: "Academic",
        taskType: "Standard",
      }}
    >
      <Form.Item
        label="Teacher Name"
        name="teacherName"
        rules={[{ required: true, message: "Please input the teacher name!" }]}
      >
        <Input placeholder="Enter teacher's name" />
      </Form.Item>

      <Form.Item
        label="Grade"
        name="grade"
        initialValue={gradeDivisions[0]?.name}
        rules={[{ required: true, message: "Please select a grade" }]}
      >
        <Select
          value={selectedGrade}
          onChange={handleGradeChange}
          style={{ marginBottom: 20 }}
          name="grade"
        >
          {gradeDivisions?.map((gra, index) => (
            <Option key={index} value={gra.name}>
              {gra.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="Section"
        name="section"
        rules={[{ required: true, message: "Please select a section!" }]}
      >
        <Select>
          <Option value="A">Section A</Option>
          <Option value="B">Section B</Option>
          <Option value="C">Section C</Option>
          {/* Add more options as needed */}
        </Select>
      </Form.Item>
      <Form.Item
        label="Subject Name"
        name="subjectName"
        rules={[{ required: true, message: "Please input the subject name!" }]}
      >
        <Input placeholder="Enter subject name" />
      </Form.Item>
      <Form.Item
        label="Subject Type"
        name="subjectType"
        rules={[{ required: true, message: "Please select a subject type!" }]}
      >
        <Select>
          <Option value="Academic">Academic</Option>
          <Option value="Non-Academic">Non-Academic</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Task Type"
        name="taskType"
        rules={[{ required: true, message: "Please select a task type!" }]}
      >
        <Select>
          <Option value="Standard">Standard</Option>
          <Option value="Home Room">Home Room</Option>
        </Select>
      </Form.Item>
    </Form>
  );
};

export default TeacherTaskForm;
