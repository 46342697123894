import React, { useState, useMemo } from "react";
import { Button, Input, Row, Col, Select } from "antd";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import DynamicTable from "../DynamicTable";
import { convertToDisplayDate } from "../../../utils/helper";

const excludedCols = [
  "ROLL NUM",
  "STUD ID",
  "FULL NAME",
  "SEX",
  "GRADE",
  "REGION",
  "SUB CITY",
  "WOREDA",
  "MOTHER NAME",
  "AGE",
  "SECTION",
  "PHONE",
  "PHONE HOME",
  "CREATED",
  "UPDATED",
  "CREATED BY",
];

const generateColumns = (data) => {
  if (!data || data.length === 0) return [];

  const commonColumns = [
    {
      title: "INFO",
      key: "info",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            Name: {record["FULL NAME"].toUpperCase()}
          </div>
          <div className="tableSubCols details">
            Role Num: {record["ROLL NUM"]}
          </div>
          <div className="tableSubCols details">ID: {record["STUD ID"]}</div>
          <div className="tableSubCols details">Sex: {record["SEX"]}</div>
        </>
      ),
    },
    {
      title: "PHONE",
      key: "phone",
      render: (text, record) => (
        <>
          <div className="tableSubCols details">Phone: {record["PHONE"]}</div>
          <div className="tableSubCols details">
            Home: {record["PHONE HOME"]}
          </div>
        </>
      ),
    },
    {
      title: "SECTION",
      key: "section",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {record["GRADE"]}
          </div>
          <div className="tableSubCols details">
            {" "}
            Section: {record["SECTION"]}
          </div>
        </>
      ),
    },
    {
      title: "REGION",
      key: "region",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            Region: {record["REGION"]}
          </div>
          <div className="tableSubCols details">
            Sub City: {record["SUB CITY"]}
          </div>
          <div className="tableSubCols details">Woreda: {record["WOREDA"]}</div>
        </>
      ),
    },
    {
      title: "CREATED",
      key: "created",
      render: (text, record) => (
        <>
          <div
            className="tableSubCols heading"
            style={{ fontWeight: "bolder" }}
          >
            {convertToDisplayDate(record["CREATED"])}
          </div>
          <div className="tableSubCols details">
            Updated: {convertToDisplayDate(record["UPDATED"])}
          </div>
          <div className="tableSubCols details">
            Created by: {record["CREATED BY"]}
          </div>
        </>
      ),
    },
  ];

  const sampleItem = data[0];
  const keys = Object.keys(sampleItem).filter(
    (key) => !excludedCols.includes(key)
  );

  const dynamicColumns = keys.map((key) => ({
    title: key.charAt(0).toUpperCase() + key.slice(1),
    key,
    dataIndex: key,
  }));

  dynamicColumns.push({
    title: "Actions",
    key: "actions",
    render: (text, record) => (
      <span>
        <Button type="link" onClick={() => {}}>
          <EditOutlined />
        </Button>
      </span>
    ),
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (text, record) => (
    //     <Button onClick={() => handleRemoveTask(record.id)} danger>
    //       Remove
    //     </Button>
    //   ),
    //   },
  });

  return [...commonColumns, ...dynamicColumns];
};
// const handleRemoveTask = (id) => {
//   setTask(task.filter((task) => task.id !== id));
// };
const StudentTable = ({ studentData = [], studentLoading, setModalClosed }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const customColumns = useMemo(
    () => generateColumns(studentData),
    [studentData]
  );

  //   const filteredData = useMemo(() => {
  //     if (!studentData) return [];
  //     return studentData.filter((item) => {
  //       if (!selectedColumn || !searchText) return true;
  //       return item[selectedColumn]
  //         ?.toString()
  //         .toLowerCase()
  //         .includes(searchText.toLowerCase());
  //     });
  //   }, [studentData, searchText, selectedColumn]);
  const filteredData = useMemo(() => {
    if (!studentData) return []; // Handle null case
    console.log("datata", studentData);

    return studentData.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      console.log("iteem", item);

      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [studentData, searchText, selectedColumn]);
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const columnOptions = excludedCols.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div id="StudentTable">
      <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
        {/* <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
            aria-label="Search"
          />
        </Col>
        <Col>
          <Select
            placeholder="Select Column"
            value={selectedColumn}
            onChange={handleColumnChange}
            style={{ width: 200 }}
            allowClear
            options={columnOptions}
            aria-label="Select Column"
          />
        </Col> */}
      </Row>
      <DynamicTable
        data={filteredData}
        loading={studentLoading}
        customColumns={customColumns}
        scroll={{ x: 1800 }}
        rowKey="id"
        tableActions={[]}
      />
    </div>
  );
};

export default StudentTable;
