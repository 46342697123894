import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { checkAndNavigate } from "../utils/helper";

const baseUrl = window.location.origin;
let userAccessToken;
let schoolAccessToken;

const api = axios.create({
  baseURL: `${baseUrl}`,
  headers: {
    "Content-Type": "application/json", // Default Content-Type
  },
  withCredentials: true, // Include credentials in requests
});

// Request Interceptor
api.interceptors.request.use(
  (config) => {
    userAccessToken = localStorage.getItem("userAccessToken");
    schoolAccessToken = localStorage.getItem("schoolAccessToken");

    // Set Authorization headers
    if (userAccessToken) {
      config.headers.Authorization = `Bearer ${userAccessToken}`;
    }
    if (schoolAccessToken) {
      config.headers.schoolAccessToken = schoolAccessToken;
    }

    // Dynamically set Content-Type for FormData
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Refresh access token function
const refreshUserAccessToken = (userAccessToken) => {
  if (userAccessToken) {
    localStorage.setItem("userAccessToken", userAccessToken);
  }
};

const refreshSchoolAccessToken = (schoolAccessToken) => {
  if (schoolAccessToken) {
    localStorage.setItem("schoolAccessToken", schoolAccessToken);
  }
};

// Custom hook to handle navigation
export const useAxiosInterceptors = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const responseInterceptor = api.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.log("api error ...........>>>>>>>", error);

        const originalRequest = error.config;

        if (error.response?.status === 401) {
          const errorMessage = error.response.data.message;

          // Check if the error is due to an expired access token
          if (errorMessage === "school-token-required") {
            localStorage.setItem("schoolAccessToken", "");
            return checkAndNavigate(location, navigate, errorMessage);
          }
          if (errorMessage === "user-token-required") {
            localStorage.setItem("userAccessToken", "");
            return checkAndNavigate(location, navigate, errorMessage);
          }

          if (
            (errorMessage === "user-token-expired" ||
              errorMessage === "school-token-expired") &&
            !originalRequest._retry
          ) {
            originalRequest._retry = true;

            try {
              const { data } = await axios.get("/auth/refresh-tokens", {
                withCredentials: true,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "userAccessToken"
                  )}`,
                  schoolAccessToken: localStorage.getItem("schoolAccessToken"),
                },
              });

              refreshUserAccessToken(data.userAccessToken);
              refreshSchoolAccessToken(data.schoolAccessToken);

              api.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${data.userAccessToken}`;
              api.defaults.headers.common["schoolAccessToken"] =
                data.schoolAccessToken;

              return api(originalRequest); // Retry the original request
            } catch (refreshError) {
              console.error("Refresh token error:", refreshError);
              checkAndNavigate(location, navigate, "school-token-required");
              return Promise.reject(refreshError);
            }
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Eject the interceptor when the component unmounts
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate, location]);
};

// Example component using the custom hook
export const ApiComp = () => {
  useAxiosInterceptors();
  return <></>;
};

// API utility functions
export const postApi = async (endpoint, data) => {
  try {
    const response = await api.post(endpoint, data);
    return response?.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const getApi = async (endpoint, params = {}) => {
  try {
    const query = new URLSearchParams(params).toString();
    const response = await api.get(`${endpoint}?${query}`);
    return response?.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const deleteApi = async (endpoint) => {
  try {
    const response = await api.delete(endpoint);
    return response?.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};

export const patchApi = async (endpoint, value, params) => {
  try {
    const query = new URLSearchParams(params).toString();
    const response = await api.patch(`${endpoint}?${query}`, value);
    return response?.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};
