import React, { useState, useEffect } from "react";
import { Table, Button, Select, Modal, InputNumber, message } from "antd";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";

const { Option } = Select;

const OrganizingStudents = () => {
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classrooms, setClassrooms] = useState(0);
  const [sectionNaming, setSectionNaming] = useState("");

  // State for fetch data
  const [studentsData, setStudentsData] = useState([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  // State for post data
  const [postRes, setPostRes] = useState(null);
  const [postLoading, setPostLoading] = useState(false);
  const [postError, setPostError] = useState(null);

  const { post: postStudents, reset: resetArrange } = usePost(
    "/api/arrangeStudents",
    {
      setLoading: setPostLoading,
      setError: setPostError,
      setData: setPostRes,
    }
  );

  const { get: fetchStudents } = useFetch("/api/arrangeStudents", {
    setLoading: setFetchLoading,
    setError: setFetchError,
    setData: setStudentsData,
  });

  // Fetch students data on component mount
  useEffect(() => {
    console.log("featchhhhh", studentsData);

    fetchStudents();
  }, []);

  // Watch studentsData to log and update UI after fetch
  useEffect(() => {
    console.log("Fetched arranged students:", studentsData);
  }, [studentsData]);

  // Handle post response and refetch students data if successful
  useEffect(() => {
    if (postRes?.message) {
      message[postRes?.status || "success"](postRes?.message);

      if (postRes?.status === "success") {
        setIsModalVisible(false);
        resetArrange();
        fetchStudents();
      }
    }
  }, [postRes]);

  // Handle arrange button click to open modal with selected grade
  const handleArrange = (record) => {
    setSelectedGrade(record);
    setClassrooms(record.classrooms);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    // Filter students for the selected grade
    const studentsToArrange = studentsData.filter(
      (student) => student.grade === selectedGrade.grade
    );

    console.log("SSSSSStudent data", studentsData);
    console.log("student to arrange", studentsToArrange);

    // Map the student data into an array of student objects
    const students = studentsToArrange.map((student) => ({
      // st_unique_id: student.st_unique_id, // or any other relevant fields
      grade: student.grade,
      gender: student.gender,
      prev_average: student.prev_average,
      // You can add more fields here based on what the backend expects
    }));

    // Post the data with students included
    postStudents({
      id: selectedGrade.id,
      classrooms,
      sectionNaming,
      students, // Send the full student objects
    });
  };

  // Handle modal cancel
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
    },
    {
      title: "No. of Classrooms",
      dataIndex: "classrooms",
      key: "classrooms",
      render: (_, record) => record.classrooms || 0, // Default to 0 if classrooms not defined
    },
    {
      title: "No. of Students",
      dataIndex: "studentCount",
      key: "studentCount",
      render: (_, record) => record.studentCount || 0, // Default to 0 if student count not defined
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleArrange(record)}>
          Arrange
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1>Arrange Students</h1>
      <Table
        columns={columns}
        dataSource={studentsData}
        rowKey="grade" // Assuming 'grade' is unique for each entry
        loading={fetchLoading}
      />

      <Modal
        title={`Arrange ${selectedGrade ? selectedGrade.grade : ""}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        confirmLoading={postLoading}
      >
        <div>
          <p>Number of Classrooms:</p>
          <InputNumber
            min={1}
            value={classrooms}
            onChange={(value) => setClassrooms(value)}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <p>Section Naming:</p>
          <Select
            placeholder="Select a Section Name"
            onChange={(value) => setSectionNaming(value)}
          >
            <Option value="Alphabet">Alphabet</Option>
            <Option value="Number">Number</Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default OrganizingStudents;
