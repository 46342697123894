// SchoolCode.jsx
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, GetProps, message } from "antd";
import usePost from "../../hooks/usePost";
import PhoneNumberInput from "../phoneInput/PhoneInput";
import "./SchoolAuth.css";
import { useNavigate } from "react-router-dom";

const SchoolCode = () => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = useState(false);
  const [verifyRes, setVerifyRes] = useState(null);

  const [schoolCodeLoading, setSchoolCodeLoading] = useState(false);
  const [schoolCodeError, setSchoolCodeError] = useState(false);
  const [schoolCodeRes, setSchoolCodeRes] = useState(null);

  const { post: sendSchoolCode, reset: resetGetCodePost } = usePost(
    "/auth/school/get-code",
    {
      setLoading: setSchoolCodeLoading,
      setError: setSchoolCodeError,
      setData: setSchoolCodeRes,
    }
  );
  const { post: verifyCode, reset: resetVerifyCodePost } = usePost(
    "/auth/school/verify",
    {
      setLoading: setVerifyLoading,
      setError: setVerifyError,
      setData: setVerifyRes,
    }
  );

  const handleGetCode = async () => {
    try {
      await form.validateFields(["phone", "schoolCode"]);
      const value = await form.getFieldValue();
      await sendSchoolCode(value);
    } catch (error) {
      console.error(error);
    }
  };

  const handleVerify = async () => {
    try {
      await form.validateFields();
      const value = await form.getFieldValue();
      await verifyCode(value);
    } catch (error) {
      console.error(error);
    }
  };
  // Handle school code errors
  useEffect(() => {
    if (schoolCodeError) {
      message.error(schoolCodeError.message); // Handle the error
      resetGetCodePost(); // Reset after handling error
    }
  }, [schoolCodeError]);

  // Handle school code success response
  useEffect(() => {
    if (schoolCodeRes?.message) {
      message.success(schoolCodeRes?.message);
      resetGetCodePost();
    }
  }, [schoolCodeRes]);

  // Handle verify code errors
  useEffect(() => {
    if (verifyError?.message) {
      message.error(verifyError.message); // Handle the error
      resetVerifyCodePost(); // Reset after handling error
    }
  }, [verifyError]);

  // Handle verify code success response
  useEffect(() => {
    if (verifyRes?.status === "success" && verifyRes.token) {
      localStorage.setItem("schoolAccessToken", verifyRes.token);
      message.success(verifyRes?.message);
      resetVerifyCodePost();
      navigate("/auth/login");
    }
  }, [verifyRes]);

  return (
    <div id="SchoolCode" className="school-code-container">
      <Form
        form={form}
        layout="vertical"
        name="school_code"
        className="school-code-form"
      >
        <h1>Enter School Code</h1>

        <Form.Item
          label="School code"
          name="schoolCode"
          rules={[
            { required: true, message: "Please input your School Code!" },
          ]}
          placeholder="School Code"
        >
          <Input />
        </Form.Item>

        <PhoneNumberInput form={form} />

        <Row
          justify="space-between"
          style={{
            paddingRight: "20px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Col span={18}>
            <Form.Item
              label="verification code"
              name="verificationCode"
              rules={[
                {
                  required: true,
                  message: "Please input the verification code!",
                },
              ]}
              // hasFeedback
              validateStatus="success"
            >
              <Input.OTP />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Button onClick={handleGetCode} loading={schoolCodeLoading}>
              Get
            </Button>
          </Col>
        </Row>
        <Button onClick={handleVerify} type="primary" loading={verifyLoading}>
          Verify
        </Button>
      </Form>
    </div>
  );
};

export default SchoolCode;
