import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Card,
  Row,
  Col,
  message,
  Select,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LocationRequest } from "../../location-selector/LocationSelector";
import usePost from "../../../hooks/usePost";
import { subCitiesByRegion, woreda } from "../../../services/data";
import PhoneNumberInput from "../../phoneInput/PhoneInput";
const { Option } = Select;

const SchoolRegistration = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [createLoading, setCreateLoading] = useState(false);
  const [createError, setCreateError] = useState(null);
  const [createdRes, setCreatedRes] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState("Addis Ababa");

  const [schoolCodeLoading, setSchoolCodeLoading] = useState(false);
  const [schoolCodeError, setSchoolCodeError] = useState(false);
  const [schoolCodeRes, setSchoolCodeRes] = useState(null);

  const [matchingSubCity, setMatchingSubCity] = useState([]);

  const subscriptionType = localStorage.getItem("subscriptionType");

  const handleRegionChange = (value) => {
    setSelectedRegion(value);
  };

  const { post: sendSchoolCode, reset: resetGetCodePost } = usePost(
    "/auth/school/get-reg-code",
    {
      setLoading: setSchoolCodeLoading,
      setError: setSchoolCodeError,
      setData: setSchoolCodeRes,
    }
  );

  const { post: createSchool, reset: resetPost } = usePost(
    "/auth/school/create",
    {
      setLoading: setCreateLoading,
      setError: setCreateError,
      setData: setCreatedRes,
    }
  );

  const handleGetCode = async () => {
    try {
      await form.validateFields(["phone"]);
      const value = await form.getFieldValue();
      await sendSchoolCode(value);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("schoolAccessToken")) navigate("/auth/sign-up");
  }, []);

  // Handle school code errors
  useEffect(() => {
    if (schoolCodeError) {
      message.error(schoolCodeError.message); // Handle the error
      resetGetCodePost(); // Reset after handling error
    }
  }, [schoolCodeError]);

  // Handle school code success response
  useEffect(() => {
    if (schoolCodeRes?.message) {
      message.success(schoolCodeRes?.message);
      resetGetCodePost();
    }
  }, [schoolCodeRes]);

  useEffect(() => {
    if (createdRes?.status === "success" && createdRes.schoolToken) {
      localStorage.setItem("schoolAccessToken", createdRes.schoolToken);
      message[createdRes?.status || "success"](createdRes?.message);
      navigate("/auth/sign-up");
      form.resetFields();
      resetPost();
    }
  }, [createdRes]);

  useEffect(() => {
    console.log("createError >>>>>>>>>>>>>>>>>>>", createError);
    if (createError?.message)
      message[createError?.status || "error"](createError?.message);
    resetPost();
  }, [createError]);

  useEffect(() => {
    setMatchingSubCity(subCitiesByRegion[selectedRegion]);
    form.setFieldValue("subCity", subCitiesByRegion[selectedRegion]?.[0]);
  }, [selectedRegion]);

  const onFinish = (values) => {
    console.log("Form values: ", values);
    values.subscriptionType = subscriptionType;
    createSchool(values);
  };

  const handleLoginNavigate = () => {
    navigate("/auth/school");
  };

  // Date format for year selection only
  const yearFormat = "YYYY";

  return (
    <div id="LandingPage">
      <div className="main-nav" id="main-nav">
        <div className="container main-nav-container">
          <div
            className="logo-container"
            onClick={() => {
              console.log("clicked");
              navigate("/");
            }}
          >
            <img src="../../images/logo.svg" alt="Logo" />
            <a className="logo">
              <span className="text-secondary">TOFI</span>
            </a>
          </div>

          <div className="menu-btn-container">
            <a href="#" className="btn-menu">
              <i className="fa-solid fa-bars fa-2x"></i>
            </a>
          </div>

          <div className="main-menu">
            <ul>
              <li>
                <a href="#" className="manu-link">
                  Features
                </a>
              </li>
              <li className="pricing">
                <Link to="/pricing" className="manu-link">
                  Pricing
                </Link>
              </li>
            </ul>
          </div>
          <div className="auth-container">
            <ul className="auth-ul">
              <li>
                <button className="btn" onClick={handleLoginNavigate}>
                  Login
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Card
        title={"School Registration"}
        style={{
          margin: "32px auto",
          display: "flex",
          flexDirection: "column",
          maxWidth: "900px",
        }}
      >
        <Form
          form={form}
          name="school_registration"
          layout="vertical"
          onFinish={onFinish}
          style={{
            width: "100%", // Take full width on smaller screens
            margin: "0 auto",
            padding: "16px",
          }}
        >
          {/* School Name and School Code */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="School Name"
                name="schoolName"
                rules={[
                  { required: true, message: "Please input the school name!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="School code"
                name="schoolCode"
                rules={[
                  { required: true, message: "Please input the school code!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* School Branch Number and Branch Name */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="School's Branch Number"
                name="schBranchNum"
                rules={[
                  {
                    required: true,
                    message: "Please input the school's branch number!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="School Branch Name"
                name="schBranchName"
                rules={[
                  {
                    required: true,
                    message: "Please input the school branch name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Phone and Email */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <PhoneNumberInput form={form} />
            </Col>
            <Col xs={24} sm={12}>
              <Row
                justify="space-between"
                style={{
                  paddingRight: "20px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Col span={18}>
                  <Form.Item
                    label="verification code"
                    name="verificationCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input the verification code!",
                      },
                    ]}
                    // hasFeedback
                    validateStatus="success"
                  >
                    <Input.OTP />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Button onClick={handleGetCode} loading={schoolCodeLoading}>
                    Get
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input a valid email!",
                    type: "email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Work Year and Established Year */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Established Year"
                name="establishedYear"
                rules={[
                  {
                    required: true,
                    message: "Please select the established year!",
                  },
                ]}
              >
                <DatePicker
                  picker="year"
                  format={yearFormat}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Work Year"
                name="workYear"
                rules={[
                  { required: true, message: "Please select the work year!" },
                ]}
              >
                <DatePicker
                  picker="year"
                  format={yearFormat}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Grade Levels Offered */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Grade Levels Offered"
                name="gradesOffered"
                rules={[
                  {
                    required: true,
                    message: "Please input how many grades the school offers!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Region and Sub City */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Region"
                name="region"
                initialValue={selectedRegion}
                rules={[
                  { required: true, message: "Please select your region" },
                ]}
              >
                <Select onChange={handleRegionChange}>
                  {Object.keys(subCitiesByRegion)?.map((sub) => (
                    <Option key={sub} value={sub}>
                      {sub}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Sub City"
                name="subCity"
                rules={[
                  { required: true, message: "Please select your sub city" },
                ]}
              >
                <Select>
                  {matchingSubCity?.map((sub) => (
                    <Option key={sub} value={sub}>
                      {sub}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Woreda"
                name="woreda"
                rules={[
                  { required: true, message: "Please select your woreda" },
                ]}
              >
                <Select placeholder="Please select your woreda">
                  {woreda.map((wor, index) => (
                    <Option key={index} value={wor}>
                      {wor}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Location */}
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label="Location"
                name="location"
                rules={[
                  { required: true, message: "Please fill in your location!" },
                ]}
              >
                <LocationRequest form={form} />
              </Form.Item>
            </Col>
          </Row>

          {/* Telegram User ID and BOT Token */}
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Telegram User ID"
                name="schTelegramId"
                rules={[
                  {
                    required: true,
                    message: "Please input the Telegram user ID of the school!",
                  },
                ]}
              >
                <Input style={{ width: "70%" }} />
              </Form.Item>
              <a
                href="https://t.me/useridinfobot"
                style={{ color: "blue" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to get your school's Telegram user ID from this link.
                Make sure it's your school's ID!
              </a>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Telegram BOT TOKEN"
                name="schTelegramToken"
                rules={[
                  {
                    required: true,
                    message: "Please input the Telegram BOT token!",
                  },
                  {
                    pattern: /^[0-9]{9,10}:[A-Za-z0-9_-]{35}$/,
                    message:
                      "Invalid Telegram BOT token format! Ensure it matches the correct format.",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <a
                href="https://t.me/BotFather"
                style={{ color: "blue" }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to get your school's Telegram BOT token. Make sure
                it's for your school, and keep the token secure to prevent
                unauthorized access.
              </a>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12}>
              <Form.Item
                label="Promo Code"
                name="promoCode"
                rules={[
                  {
                    required: false,
                  },
                  {
                    pattern: /^[A-Za-z0-9]{5,10}$/, // Alphanumeric, 5-10 characters
                    message: "Promo code must be 5-10 alphanumeric characters",
                  },
                ]}
              >
                <Input style={{ width: "70%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button loading={createLoading} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SchoolRegistration;
