import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PaymentSuccess = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const tx_ref = queryParams.get("tx_ref");
    const status = queryParams.get("status");

    if (status === "success") {
      console.log("Payment successful for transaction:", tx_ref);
      // Optionally, confirm the payment with the backend
    } else {
      console.error("Payment failed or canceled");
    }
  }, [queryParams]);

  return (
    <div>
      <h1>Payment {queryParams.get("status")}</h1>
    </div>
  );
};

export default PaymentSuccess;
