import React, { useState } from "react";
import { Table, Button, Modal, Select, Row, Col, Input } from "antd";
import PaymentForm from "../forms/payment-form/PaymentForm";
import ReceiptModal from "../modal/receipit/Receipit";

const Payment = () => {
  const [payments, setPayments] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [isReceiptModalVisible, setIsReceiptModalVisible] = useState(false);
  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleAddPayment = (paymentData) => {
    const newPayment = {
      ...paymentData,
      id: payments.length + 1,
      status: "Pending",
    };
    setPayments([...payments, newPayment]);
    setIsPaymentModalVisible(false);
  };

  const handlePendingChange = (id, status) => {
    setPayments((prevPayments) =>
      prevPayments.map((payment) =>
        payment.id === id ? { ...payment, status } : payment
      )
    );
  };

  const handlePrint = (payment) => {
    setSelectedPayment(payment);
    setIsReceiptModalVisible(true);
  };

  const columns = [
    {
      title: "Payment ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Student Name",
      dataIndex: "studentSearch",
      key: "studentSearch",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (amount) => `$${amount.toFixed(2)}`,
    },
    {
      title: "Payment method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, record) => (
        <Select
          value={status}
          onChange={(value) => handlePendingChange(record.id, value)}
        >
          <Select.Option value="Pending">Pending</Select.Option>
          <Select.Option value="Approved">Approved</Select.Option>
          <Select.Option value="Declined">Declined</Select.Option>
          <Select.Option value="Incomplete">Incomplete</Select.Option>
        </Select>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          disabled={record.status !== "Approved"}
          onClick={() => handlePrint(record)}
        >
          Print Receipt
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h1>Payment Page</h1>
      <Row gutter={[16, 16]} style={{ margin: 20 }}>
        <Col>
          <Input
            placeholder="Search"
            value={searchText}
            onChange={handleSearchChange}
            style={{ width: 200 }}
          />
        </Col>
        <Col flex="auto">
          <Button
            style={{ float: "right" }}
            type="primary"
            onClick={() => setIsPaymentModalVisible(true)}
          >
            Add Payment
          </Button>
        </Col>
      </Row>

      <Table
        dataSource={payments}
        columns={columns}
        rowKey="id"
        scroll={{ x: 1400 }}
      />

      {/* Payment Form Modal */}
      <Modal
        visible={isPaymentModalVisible}
        title="Add Payment"
        footer={null}
        onCancel={() => setIsPaymentModalVisible(false)}
      >
        <PaymentForm onCreate={handleAddPayment} />
      </Modal>

      {/* Receipt Modal */}
      <ReceiptModal
        visible={isReceiptModalVisible}
        onOk={() => window.print()}
        onCancel={() => setIsReceiptModalVisible(false)}
        receiptData={selectedPayment}
      />
    </div>
  );
};

export default Payment;
