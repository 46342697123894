import React, { useState, useMemo, useEffect } from "react";
import { Table, Button, Select, Input, Row, Col, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";
import DynamicTable from "../../tables/DynamicTable";
import useFetch from "../../../hooks/useFetch";
import { grade, section } from "../../../services/data";
import { convertToDisplayDate } from "../../../utils/helper";
import { printStudId } from "../../../utils/printId.js";

const StudentId = () => {
  const [showPrintId, setShowPrintId] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [selectedGrade, setSelectedGrade] = useState("Nursery");
  const [selectedSection, setSelectedSection] = useState("A");

  const [studentsNeedingId, setStudentsNeedingId] = useState([]);
  const [fetchStudLoading, setFetchStudLoading] = useState(false);
  const [fetchStudError, setFetchStudError] = useState(null);
  const [StudData, setStudData] = useState([]);

  // state for id featching

  const [fetchIdLoading, setFetchIdLoading] = useState(false);
  const [fetchIdError, setFetchIdError] = useState(null);
  const [idData, setIdData] = useState([]);

  // Fetching the students from the backend
  const { get: fetchStudent } = useFetch("/api/student", {
    setLoading: setFetchStudLoading,
    setError: setFetchStudError,
    setData: setStudData,
  });

  // Featching Student Ids from backend

  const { get: fetchId } = useFetch("/api/studentId", {
    setLoading: setFetchIdLoading,
    setError: setFetchIdError,
    setData: setIdData,
  });

  useEffect(() => {
    fetchStudent({ grade: selectedGrade, section: selectedSection }); // Fetch students whenever the selected grade or section changes
  }, [selectedGrade, selectedSection]);

  useEffect(() => {
    fetchId();
  }, []);

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  const handleAddStudentForId = (student) => {
    if (!studentsNeedingId.some((s) => s["STUD ID"] === student["STUD ID"])) {
      setStudentsNeedingId((prev) => [...prev, student]);
    } else {
      message.info("Student is already in the list for ID generation.");
    }
  };
  const handleRemoveStudent = (studentId) => {
    setStudentsNeedingId((prev) =>
      prev.filter((s) => s["STUD ID"] !== studentId)
    );
  };

  const excludedCols = [
    "ROLL NUM",
    "STUD ID",
    "FULL NAME",
    "GRADE",
    "SEX",
    "REGION",
    "SUB CITY",
    "WEREDA",
    "MOTHER NAME",
    "AGE",
    "SECTION",
    "PHONE",
    "PHONE HOME",
    "CREATED",
    "UPDATED",
    "CREATED BY",
  ];

  const generateColumns = (data, isForSecondTable = false) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "INFO",
        key: "info",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record["FULL NAME"]?.toUpperCase()}
            </div>
            <div className="tableSubCols details">
              Role Num: {record["ROLL NUM"]}
            </div>
            <div className="tableSubCols details">ID: {record["STUD ID"]}</div>
            <div className="tableSubCols details">Sex: {record["SEX"]}</div>
          </>
        ),
      },
      {
        title: "PHONE",
        key: "phone",
        render: (text, record) => (
          <>
            <div className="tableSubCols details">Phone: {record["PHONE"]}</div>
            <div className="tableSubCols details">
              Home: {record["PHONE HOME"]}
            </div>
          </>
        ),
      },
      {
        title: "GRADE",
        key: "grade",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["SECTION"]}</div>
            <div className="tableSubCols details">{record["GRADE"]}</div>
          </>
        ),
      },
      {
        title: "REGION",
        key: "region",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["REGION"]}</div>
            <div className="tableSubCols details">
              Sub City: {record["SUB CITY"]}
            </div>
            <div className="tableSubCols details">
              WOREDA: {record["WEREDA"]}
            </div>
            <div className="tableSubCols details">
              Mother: {record["MOTHER NAME"]}
            </div>
          </>
        ),
      },
      {
        title: "CREATED",
        key: "created",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {convertToDisplayDate(record["CREATED"])}
            </div>
            <div className="tableSubCols details">
              Updated: {convertToDisplayDate(record["UPDATED"])}
            </div>
            <div className="tableSubCols details">
              Created by: {record["CREATED BY"]}
            </div>
          </>
        ),
      },
    ];

    const generateIdColumn = {
      title: "Generate ID",
      key: "generateId",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleAddStudentForId(record)}>
          Add
        </Button>
      ),
    };

    const removeIdStud = {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          // type="primary"
          // danger
          onClick={() => handleRemoveStudent(record["STUD ID"])}
          style={{ borderColor: "red", color: "red" }}
        >
          Remove
        </Button>
      ),
    };

    return isForSecondTable
      ? [...commonColumns, removeIdStud]
      : [...commonColumns, generateIdColumn];
  };

  const customColumns = useMemo(() => generateColumns(StudData), [StudData]);
  const secondTableColumns = useMemo(
    () => generateColumns(studentsNeedingId, true),
    [studentsNeedingId]
  );

  const filteredData = useMemo(() => {
    if (!StudData) return [];
    return StudData.filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [StudData, searchText, selectedColumn]);

  // const handlePrintId = () => {
  //   if (studentsNeedingId.length > 0) {
  //     console.log("Printing IDs for students:", studentsNeedingId);
  //   }
  // };

  const handlePrintId = () => {
    if (studentsNeedingId.length > 0) {
      // setStudentsToPrint(studentsNeedingId); // Set the students to print
      printStudId(studentsNeedingId, idData.imgUri);
      setShowPrintId(true); // Trigger PrintId to render and create the PDF
    } else {
      message.warning("No students available to print IDs.");
    }
  };
  useEffect(() => {
    if (showPrintId) {
      const timer = setTimeout(() => {
        setShowPrintId(false);
      }, 500); // Adjust delay as needed
      return () => clearTimeout(timer);
    }
  }, [showPrintId]);

  const handlePrintAll = () => {
    if (filteredData.length > 0) {
      console.log("Printing IDs for all students:", filteredData);
    } else {
      message.warning("No students available to print IDs.");
    }
  };

  const gradeOptions = grade.map((col) => ({
    key: col.val,
    value: col.val,
  }));
  const sectionOption = section.map((sec) => ({
    key: sec,
    value: sec,
  }));
  const columnOptions = excludedCols.map((col) => ({
    key: col,
    value: col,
  }));

  return (
    <div id="StudentId">
      <div className="header">
        <h2>Students</h2>
        <Breadcrumb />
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="grade"
              value={selectedGrade}
              onChange={(val) => setSelectedGrade(val)}
              style={{ width: 200 }}
              allowClear
              options={gradeOptions}
            />
          </Col>
          <Col>
            <Select
              placeholder="section"
              value={selectedSection}
              onChange={(val) => setSelectedSection(val)}
              style={{ width: 200 }}
              allowClear
              options={sectionOption}
            />
          </Col>

          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
              options={columnOptions}
            />
          </Col>
        </Row>

        {/* Print All Button */}
        <div style={{ textAlign: "right", marginBottom: "10px" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handlePrintAll}
          >
            Print All
          </Button>
        </div>

        {/* First Table */}
        <DynamicTable
          data={filteredData}
          tableActions={[]}
          loading={fetchStudLoading}
          customColumns={customColumns}
          scroll={{ x: 1500 }}
        />

        {/* Second Table */}
        <h2 style={{ marginTop: "20px" }}>Students Who Need ID</h2>
        <DynamicTable
          data={studentsNeedingId}
          tableActions={[]}
          customColumns={secondTableColumns}
          scroll={{ x: 1500 }}
        />

        {/* Print ID Button */}
        {/* <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handlePrintId}
            disabled={studentsNeedingId.length === 0}
          >
            Print ID
          </Button>
        </div> */}
        <div id="StudentId">
          {/* ... (existing UI for StudentId) */}
          <div style={{ textAlign: "right", marginTop: "20px" }}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handlePrintId}
              disabled={studentsNeedingId.length === 0}
            >
              Print ID
            </Button>
          </div>

          {/* Conditionally render PrintId when ready to print */}
          {/* {showPrintId && idData && (
            <PrintId students={studentsToPrint} imgUri={idData.imgUri} />
          )} */}
        </div>
      </div>
    </div>
  );
};

export default StudentId;
