import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { message } from "antd";

import { AccAuthProvider, useAccAuth } from "./context/UserAccountAuth";
import useFetch from "./hooks/useFetch";
import WebRoutes from "./router/WebRoutes";
import AppRoutes from "./router/AppRoutes";
import { ApiComp } from "./services/api";
import PathTracker from "./components/pathTracker/PathTracker";
function App() {
  const [, contextHolder] = message.useMessage();

  return (
    <>
      {contextHolder}
      <Router>
        <ApiComp />
        <PathTracker />
        <AccAuthProvider>
          <Routes>
            <Route path="/app/*" element={<AppRoutes />} />
            <Route path="/*" element={<WebRoutes />} />
          </Routes>
        </AccAuthProvider>
      </Router>
    </>
  );
}

export default App;
