import React, { useState, useMemo, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Input,
  Table,
  Modal,
  Form,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import TeacherTaskForm from "../../forms/teacher-task-form/TeacherTaskForm";
import TaskTable from "../../tables/teacher-task-table/TeacherTaskTable";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";

const TeacherTask = () => {
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [task, setTask] = useState([]);
  const [searchText, setSearchText] = useState("");
  // use states for post
  const [taskCreatedRes, setTaskCreatedRes] = useState(null);
  const [taskCreateLoading, setTaskCreatedLoading] = useState(false);
  const [taskCreateError, setTaskCreatedError] = useState(false);
  // Refactored useFetch for teacher Task
  const [tasks, setTasks] = useState(null);
  const [taskLoading, setTaskLoading] = useState(false);
  const [taskFetchError, setTaskFetchError] = useState(null);

  const { post: createTask, reset: resetCreateTaskPost } = usePost(
    "/api/teacherTask",
    {
      setLoading: setTaskCreatedLoading,
      setError: setTaskCreatedError,
      setData: setTaskCreatedRes,
    }
  );

  const { get: fetchTask } = useFetch("/api/teacherTask", {
    setLoading: setTaskLoading,
    setError: setTaskFetchError,
    setData: setTasks,
  });

  useEffect(() => {
    fetchTask();
  }, []);

  useEffect(() => {
    if (taskCreatedRes?.message)
      message[taskCreatedRes?.status || "success"](taskCreatedRes?.message);

    if (taskCreatedRes?.status === "success") {
      setIsModalVisible(false);

      form.resetFields();
      resetCreateTaskPost();
      fetchTask();
    }
  }, [taskCreatedRes]);

  const handleAddTasks = () => {
    setIsModalVisible(true);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const filteredTasks = useMemo(() => {
    return task.filter(
      (task) =>
        task.teacherName.toLowerCase().includes(searchText.toLowerCase()) ||
        task.grade.toLowerCase().includes(searchText.toLowerCase()) ||
        task.section.toLowerCase().includes(searchText.toLowerCase()) ||
        task.subjectName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [task, searchText]);
  const handleAddOk = async () => {
    const values = await addForm.validateFields();
    await createTask({
      ...values,
    });

    console.log("here are the values:", values);
  };

  return (
    <div id="TeacherTask">
      <h2>Teacher Task Management</h2>
      <Card bordered={false} style={{ margin: "20px" }}>
        <div style={{ float: "right", marginBottom: 10 }}>
          <Button
            type="primary"
            onClick={handleAddTasks}
            style={{ marginLeft: 10 }}
            loading={taskLoading}
          >
            Add New Task
          </Button>
        </div>
        <TaskTable
          taskData={tasks}
          setModalClosed={showModal}
          taskLoading={taskLoading}
        />

        {/* <Table
          columns={columns}
          dataSource={filteredTasks}
          rowKey={(record) => record.id}
        /> */}
        <Modal
          title="Add New Task"
          onOk={handleAddOk}
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
        >
          <TeacherTaskForm form={addForm} />
        </Modal>
      </Card>
    </div>
  );
};

export default TeacherTask;
