import React, { useEffect, useState } from "react";
import {
  FaUsers,
  FaChalkboardUser,
  FaHandsHoldingChild,
  FaMoneyBillTransfer,
} from "react-icons/fa6";
import "./TeacherDashboard.css";
import { doughnutChartData } from "../../../services/data";
import Chart from "../../chart/Chart";
import Calender from "../../calender/Calender";
import { Card, Row, Col } from "antd";
import CustomCard from "../../card/CustomCard";
import NoticeBoard from "../../notice-board/NoticeBoard";
import useFetch from "../../../hooks/useFetch";

function TeacherDashboard() {
  // State management for summary data
  const [summaryData, setSummaryData] = useState([]);

  // State for dashboard data fetching
  const [dashLoading, setDashLoading] = useState(false);
  const [dashError, setDashError] = useState(null);
  const [dashFetchRes, setDashFetchRes] = useState([]);

  const { get: fetchDash } = useFetch("/api/admin-dashboard", {
    setLoading: setDashLoading,
    setError: setDashError,
    setData: setDashFetchRes,
  });

  const dashIcons = [
    {
      icon: <FaUsers />,
      background: "linear-gradient(135deg, #34b3a0, #00796b)", // Teal to deep green
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
    {
      icon: <FaChalkboardUser />,
      background: "linear-gradient(135deg, #1E90FF, #0047AB)", // Bright blue to navy blue
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
    {
      icon: <FaHandsHoldingChild />,
      background: "linear-gradient(135deg, #FF6F61, #D84315)", // Coral to deep orange
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
    {
      icon: <FaMoneyBillTransfer />,
      background: "linear-gradient(135deg, #4CAF50, #388E3C)", // Green gradient
      reflectiveOverlay:
        "linear-gradient(135deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0))", // Reduced opacity
    },
  ];

  useEffect(() => {
    fetchDash();
  }, []);

  useEffect(() => {
    setSummaryData(
      // dashFetchRes?.map((data, i) => {
      //   return { ...data, ...dashIcons?.[i] };
      // })
      [{ a: "b" }]
    );
  }, [dashFetchRes]);

  return (
    <div id="DashboardMain">
      <section className="main-content">
        <ul>
          <li>
            <a href="btn">Home</a>
          </li>
        </ul>
        <Row gutter={16}>
          {summaryData?.map((school, index) => {
            const { name, amount, icon, background } = school;
            return (
              <Col
                key={index}
                xs={24}
                sm={12}
                lg={6}
                style={{ marginBottom: 10 }}
              >
                <Card
                  className="custom-card"
                  style={{ background: background }}
                  title={name}
                  bordered={false}
                  hoverable
                  loading={dashLoading}
                >
                  <CustomCard amount={amount} icon={icon} />
                </Card>
              </Col>
            );
          })}
        </Row>

        <Row style={{ width: "100%" }} gutter={[16, 16]}>
          <Col sm={24} lg={12}>
            <Chart chartData={doughnutChartData} />
          </Col>
          <Col sm={24} lg={12}>
            <Chart chartData={doughnutChartData} />
          </Col>
          <Col sm={24} lg={12}>
            <Calender />
          </Col>
          <Col sm={24} lg={12}>
            <NoticeBoard />
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default TeacherDashboard;
