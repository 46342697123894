import React, { useState } from "react";
import { Menu, Layout } from "antd";
import SiderHeader from "../../sider-header/SiderHeader";
import { AdminMenuItems } from "../../../services/data";
import { Link } from "react-router-dom";
const { Sider } = Layout;

function AdminSider() {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };
  const [openKeys, setOpenKeys] = useState([]);

  const handleSubMenuClick = (key) => {
    setOpenKeys((prevOpenKeys) =>
      prevOpenKeys.includes(key)
        ? prevOpenKeys.filter((k) => k !== key)
        : [...prevOpenKeys, key]
    );
  };

  const items = AdminMenuItems.map((menuItem) => {
    if (menuItem.submenu) {
      return {
        key: menuItem.key,
        icon: menuItem.icon,
        label: (
          <span onClick={() => handleSubMenuClick(menuItem.key)}>
            {menuItem.label}
          </span>
        ),
        children: menuItem.submenu.map((subItem) => ({
          key: subItem.name,
          label: <Link to={`/app/${subItem.href}`}>{subItem.name}</Link>,
        })),
      };
    } else {
      return {
        key: menuItem.key,
        icon: menuItem.icon,
        label: <Link to={`/app/${menuItem.href}`}>{menuItem.label}</Link>,
      };
    }
  });

  return (
    <Sider
      className="sider-comp"
      width={250}
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      breakpoint="md"
      collapsedWidth="0"
    >
      <SiderHeader />
      <Menu
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        items={items}
      />
    </Sider>
  );
}

export default AdminSider;
