export function generateRandomHexColors(numColors) {
  const colorList = [];
  for (let i = 0; i < numColors; i++) {
    let hexCode = "#";
    // Generate 6 random hexadecimal digits
    for (let j = 0; j < 6; j++) {
      hexCode += Math.floor(Math.random() * 16).toString(16);
    }
    colorList.push(hexCode);
  }
  return colorList;
}

export const colorData = ["#059bff", "#ff4069", "#059bff", "#ff4069"];

export const handleFilterNumber = (e) => {
  if (!/^\d$/.test(e.key)) {
    e.preventDefault();
  }
};

export const convertToDisplayDate = (date) => {
  const dateObj = new Date(date);
  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return new Intl.DateTimeFormat("en-GB", options).format(dateObj);
};

export const checkAndNavigate = (location, navigate, message) => {
  const lastUrl = localStorage.getItem("lastUrl");
  const userToken = localStorage.getItem("userAccessToken");
  const schoolToken = localStorage.getItem("schoolAccessToken");
  if (!schoolToken) {
    return navigate("/auth/school");
  }
  if (!userToken && lastUrl?.includes("/app")) {
    return navigate("/auth/login");
  }
  if (message === "user-token-required") {
    return navigate("/auth/login");
  }
  if (message === "school-token-required") {
    return navigate("/auth/school");
  }
  if (location) return navigate(location);
  navigate(lastUrl || "/");
};

export const handleLogout = () => {
  localStorage.setItem("userAccessToken", "");
  window.location.replace("/");
};
