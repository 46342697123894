import React, { useState, useEffect, useMemo } from "react";
import { Table, Button, Select, Input, Row, Col, message, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Breadcrumb from "../../bread-crumbs/BreadCrumbs";
import DynamicTable from "../../tables/DynamicTable";
import useFetch from "../../../hooks/useFetch";
import { convertToDisplayDate } from "../../../utils/helper";
import { printStudId } from "../../../utils/printId.js";
import { printStaffId } from "../../../utils/printStaffId.js";
const StaffId = () => {
  const [showPrintId, setShowPrintId] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("FULL NAME");
  const [staffToGenerateID, setStaffToGenerateID] = useState([]);
  const [fetchIdLoading, setFetchIdLoading] = useState(false);
  const [fetchIdError, setFetchIdError] = useState(null);
  const [idData, setIdData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [staffData, setStaffData] = useState([]);

  // Fetch data hooks
  const { get: fetchStaffs } = useFetch("/api/user", {
    setLoading,
    setData: setStaffData,
  });
  const { get: fetchId } = useFetch("/api/staffId", {
    setLoading: setFetchIdLoading,
    setError: setFetchIdError,
    setData: setIdData,
  });

  useEffect(() => {
    fetchStaffs();
    fetchId();
  }, []);

  const handleSearchChange = (e) => setSearchText(e.target.value);
  const handleColumnChange = (value) => setSelectedColumn(value);

  // const addStaffToGenerateID = (staff) => {
  //   if (!staffToGenerateID.some((s) => s["ID"] === staff["ID"])) {
  //     setStaffToGenerateID((prev) => [...prev, staff]);
  //   } else {
  //     message.info("Staff is already in the list for ID generation.");
  //   }
  // };
  const addStaffToGenerateID = (staff) => {
    // Log current state and incoming staff
    console.log("Current staffToGenerateID:", staffToGenerateID);
    console.log("Attempting to add staff with ID:", staff["ID"]);

    if (staffToGenerateID.some((s) => s["ID"] === staff["ID"])) {
      message.info("Staff is already in the list for ID generation.");
      console.log("Duplicate detected for staff ID:", staff["ID"]);
    } else {
      setStaffToGenerateID((prev) => [...prev, staff]);
      console.log("Added staff with ID:", staff["ID"]);
    }
  };

  const removeStaffFromGenerateID = (staffID) => {
    setStaffToGenerateID((prev) => prev.filter((s) => s["ID"] !== staffID));
  };

  useEffect(() => {
    if (showPrintId) {
      const timer = setTimeout(() => {
        setShowPrintId(false);
      }, 500); // Adjust delay as needed
      return () => clearTimeout(timer);
    }
  }, [showPrintId]);

  const generateColumns = (data, isForSecondTable = false) => {
    if (!data || data.length === 0) return [];

    const commonColumns = [
      {
        title: "INFO",
        key: "info",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {record["FULL NAME"].toUpperCase()}
            </div>
            <div className="tableSubCols details">ID: {record["ID"]}</div>
            <div className="tableSubCols details">Sex: {record["SEX"]}</div>
            <div className="tableSubCols details">
              Edu Level: {record["EDUC LEVEL"]}
            </div>
          </>
        ),
      },
      {
        title: "PHONE",
        key: "phone",
        render: (text, record) => (
          <>
            <div className="tableSubCols details">Phone: {record["PHONE"]}</div>
            <div className="tableSubCols details">Email: {record["EMAIL"]}</div>
            <div className="tableSubCols details">Role: {record["ROLE"]}</div>
          </>
        ),
      },
      {
        title: "REGION",
        key: "region",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">{record["REGION"]}</div>
            <div className="tableSubCols heading">
              Woreda: {record["WOREDA"]}
            </div>

            <div className="tableSubCols details">
              Sub City: {record["SUB CITY"]}
            </div>
          </>
        ),
      },
      {
        title: "CREATED",
        key: "created",
        render: (text, record) => (
          <>
            <div className="tableSubCols heading">
              {convertToDisplayDate(record["CREATED"])}
            </div>
            <div className="tableSubCols details">
              Updated: {convertToDisplayDate(record["UPDATED"])}
            </div>
            <div className="tableSubCols details">
              Created by: {record["CREATED BY"]}
            </div>
          </>
        ),
      },
    ];

    const generateIdColumn = {
      title: "Generate ID",
      key: "generateId",
      render: (text, record) => (
        <Button type="primary" onClick={() => addStaffToGenerateID(record)}>
          Add
        </Button>
      ),
    };

    const removeIdColumn = {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button
          onClick={() => removeStaffFromGenerateID(record["ID"])}
          style={{ borderColor: "red", color: "red" }}
        >
          Remove
        </Button>
      ),
    };

    return isForSecondTable
      ? [...commonColumns, removeIdColumn]
      : [...commonColumns, generateIdColumn];
  };

  const customColumns = useMemo(() => generateColumns(staffData), [staffData]);
  const secondTableColumns = useMemo(
    () => generateColumns(staffToGenerateID, true),
    [staffToGenerateID]
  );

  const filteredData = useMemo(() => {
    return (staffData || []).filter((item) => {
      if (!selectedColumn || !searchText) return true;
      return item[selectedColumn]
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
  }, [staffData, searchText, selectedColumn]);

  const handlePrintId = () => {
    if (staffToGenerateID.length > 0) {
      message.success("ID(s) Printed!");
      printStaffId(staffToGenerateID, idData.imgUri);
      setShowPrintId(true); // Trigger PrintId to render and create the PDF
    } else {
      message.warning("No staff selected to print IDs.");
    }
  };

  return (
    <div id="StaffId">
      <div className="header">
        <h2>Staff ID Management</h2>
        <Breadcrumb />
      </div>
      <Card
        title="Manage Staff IDs"
        bordered={false}
        style={{ margin: "20px" }}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Input
              placeholder="Search"
              value={searchText}
              onChange={handleSearchChange}
              style={{ width: 200 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Select Column"
              value={selectedColumn}
              onChange={handleColumnChange}
              style={{ width: 200 }}
              allowClear
            >
              {[
                "FULL NAME",
                "EMAIL",
                "EDUC LEVEL",
                "ROLE",
                "SEX",
                "REGION",
                "SUB CITY",
                "PHONE",
                "CREATED",
                "UPDATED",
                "CREATED BY",
              ].map((col) => (
                <Select.Option key={col} value={col}>
                  {col}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col>
            <DynamicTable
              title="All Staff"
              data={filteredData}
              customColumns={customColumns}
              loading={loading}
              scroll={{ x: 700 }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DynamicTable
              title="Staff Who Need ID"
              data={staffToGenerateID}
              customColumns={secondTableColumns}
              loading={false}
              scroll={{ x: 700 }}
            />
            <Button
              type="primary"
              disabled={staffToGenerateID.length === 0}
              style={{ marginTop: 16 }}
              onClick={handlePrintId}
            >
              Print ID
            </Button>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default StaffId;
