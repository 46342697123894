import React from "react";
import { Form, Input, Button, Select } from "antd";
const { Option } = Select;

const AnnouncementForm = ({
  form,

  selectedGrade,
  selectedSection,
}) => {
  const onFinish = (values) => {
    const announcementDetails = {
      message: values.announcementMessage,
      grade: selectedGrade,
      section: selectedSection,
      receiver: values.receiver,
      timestamp: new Date(), // Add timestamp here
    };

    console.log("Announcement Submitted: ", announcementDetails);
    // onSendAnnouncement(values.announcementMessage, values.receiver);
    form.resetFields();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={{ announcementMessage: "" }}
    >
      <Form.Item
        label="Receiver"
        name="receiver"
        rules={[{ required: true, message: "Please select the receiver!" }]}
      >
        <Select>
          <Option value="student">Student</Option>
          <Option value="parent">Parent</Option>
          <Option value="staff">Staff</Option>
          <Option value="teacher">Teacher</Option>

          {/* Add more options as needed */}
        </Select>
      </Form.Item>
      <Form.Item
        label="Announcement Message"
        name="announcementMessage"
        rules={[{ required: true, message: "Please input the announcement!" }]}
      >
        <Input.TextArea
          rows={4}
          placeholder="Write your announcement here..."
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Send Announcement
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AnnouncementForm;
