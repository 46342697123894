import { useRoutes } from "react-router-dom";
import PaymentSuccess from "../../components/payment/PaymentSuccess";
import SubscriptionPayment from "../../pages/subscription-payment/SubscriptionPayment";

const SubscriptionPaymentRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <SubscriptionPayment /> },
    {
      path: "/chapa/success",
      element: <PaymentSuccess />,
    },
  ]);

  return routes;
};

export default SubscriptionPaymentRoutes;
