import React from "react";
import { FaBell, FaQuestion, FaUser } from "react-icons/fa";
import { Button, Layout, Menu } from "antd";
import "./Header.css";
import { handleLogout } from "../../utils/helper.js";
import { useNavigate } from "react-router-dom";

const { Header } = Layout;

function HeaderComp() {
  const navigate = useNavigate();
  const schoolName = localStorage.getItem("schoolName");
  const schoolLogo = localStorage.getItem("schoolLogo");

  console.log("schoolLogo ...........", schoolLogo);

  const menuItems = [
    {
      key: "1",
      icon: <FaBell />,
    },
    {
      key: "2",
      icon: <FaQuestion />,
    },
    {
      key: "SubMenu",
      icon: <FaUser />,
      children: [
        {
          key: "3",
          label: <Button onClick={handleLogout}>Logout</Button>,
        },
      ],
    },
  ];

  return (
    <Header id="Header">
      <div
        className="header-left"
        onClick={() => {
          navigate("/");
        }}
      >
        <div className="logo">
          <img
            className="logo-img"
            src={schoolLogo?.replace("./", "/")}
            alt="logo"
          />
          <span>{schoolName}</span>
        </div>
      </div>
      <Menu mode="horizontal" className="menu" items={menuItems} />
    </Header>
  );
}

export default HeaderComp;
