// AppRoutes.js
import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loader from "../components/loader/Loader";
import ErrorRoutes from "./ErrorRoutes";
import Administration from "../pages/app/administration/Administration";
import Registrar from "../pages/app/registrar/Registrar";
import Teacher from "../pages/app/teacher/Teacher";
import SchoolRegistration from "../components/registration-form-container/school-registration/SchoolRegistration";
import AdminQuestionPage from "../pages/app/question-generator-page/AdminQuestionPage";

const AppRoutes = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/admin/*" element={<Administration />} />
        <Route path="/registrar/*" element={<Registrar />} />
        <Route path="/teacher/*" element={<Teacher />} />
        <Route path="/school-registration" element={<SchoolRegistration />} />
        <Route path="/admin-ai-analysis/*" element={<AdminQuestionPage />} />
        <Route path="*" element={<ErrorRoutes />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
