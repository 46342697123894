import React, { useEffect, useState } from "react";
import { grade } from "../../../services/data";
import { PlusOutlined } from "@ant-design/icons";
import {
  Table,
  Button,
  Select,
  Popconfirm,
  message,
  Modal,
  Form,
  Input,
  Card,
  Row,
  Col,
} from "antd";
import "./AssessmentList.css";
import AssessmentForm from "../../forms/assessment-form/AssessmentForm";
import DynamicTable from "../../tables/DynamicTable";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import usePatch from "../../../hooks/usePatch";
import useDelete from "../../../hooks/useDelete";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";

const { Option } = Select;

const Assessment = () => {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const [searchText, setSearchText] = useState("");
  const [selectedColumn, setSelectedColumn] = useState("name");
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedGrade, setSelectedGrade] = useState("");
  const [editingAssessment, setEditingAssessment] = useState(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [form] = Form.useForm(); // Define form instance

  // Fetch related states
  const [assessments, setAssessments] = useState([]);
  const [assLoading, setAssLoading] = useState(false);
  const [fetchAssError, setFetchAssError] = useState(null);

  // Create related states
  const [assCreateLoading, setAssCreateLoading] = useState(false);
  const [assCreateError, setAssCreateError] = useState(null);
  const [assCreatedRes, setAssCreatedRes] = useState(null);

  // Modify related states
  const [assModifyLoading, setAssModifyLoading] = useState(false);
  const [assModifyError, setAssModifyError] = useState(null);
  const [assModifyRes, setAssModifyRes] = useState(null);

  // Delete related states
  const [assDeleteLoading, setAssDeleteLoading] = useState(false);
  const [assDeleteError, setAssDeleteError] = useState(null);
  const [assDeleteRes, setAssDeleteRes] = useState(null);

  // Fetch Assessments
  const { get: fetchAss } = useFetch("/api/assessment", {
    setLoading: setAssLoading,
    setError: setFetchAssError,
    setData: setAssessments,
  });

  console.log("gradeDivisions=============", gradeDivisions);

  // Post Assessment
  const { post: createAssess, reset: resetCreateAssPost } = usePost(
    "/api/assessment",
    {
      setLoading: setAssCreateLoading,
      setError: setAssCreateError,
      setData: setAssCreatedRes,
    }
  );

  // Update Assessment
  const { patch: modifyAssess, reset: resetAssModify } = usePatch(
    `/api/assessment/${editingAssessment?.id}`,
    {
      setLoading: setAssModifyLoading,
      setError: setAssModifyError,
      setData: setAssModifyRes,
    }
  );

  // Delete Assessment
  const { remove: removeAssess, reset: resetAssRemove } = useDelete(
    `/api/assessment`,
    {
      setLoading: setAssDeleteLoading,
      setError: setAssDeleteError,
      setData: setAssDeleteRes,
    }
  );

  // Handle Grade Selection Change
  const handleGradeChange = (val) => {
    setSelectedGrade(val);
  };

  // Fetch assessments when grade changes
  useEffect(() => {
    if (selectedGrade) fetchAss({ grade: selectedGrade });
    console.log("assessments", assessments);
  }, [selectedGrade]);

  useEffect(() => {
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (gradeDivisions?.length) setSelectedGrade(gradeDivisions?.[0]?.name);
  }, [gradeDivisions]);
  /////////
  // useEffect(() => {
  //   console.log(createAssess);
  //   if (createAssess?.message)
  //     message[createAssess?.status](createAssess?.message);

  //   if (createAssess?.status === "success") {
  //     form.resetFields();
  //   }
  //   fetchAss({ grade: selectedGrade }); // Refetch payments after a successful post
  //   resetCreateAssPost();
  // }, [createAssess]);

  // useEffect(() => {
  //   console.log(assCreateError);
  //   if (assCreateError?.message)
  //     message[assCreateError?.status || "error"](assCreateError?.message);
  //   resetCreateAssPost();
  // }, [assCreateError]);
  //
  // useEffect(() => {
  //   console.log(assModifyRes);
  //   if (assModifyRes?.message)
  //     message[assModifyRes?.status](assModifyRes?.message);

  //   if (assModifyRes?.status === "success") {
  //     form.resetFields();
  //   }
  //   fetchAss({ grade: selectedGrade }); // Refetch payments after a successful post
  //   resetAssModify();
  // }, [assModifyRes]);

  // useEffect(() => {
  //   console.log(assModifyError);
  //   if (assModifyError?.message)
  //     message[assModifyError?.status || "error"](assModifyError?.message);
  //   resetAssModify();
  // }, [assModifyError]);
  // Handle create action
  const handleCreate = async () => {
    try {
      await form.validateFields();
      createAssess({ ...form.getFieldsValue(), grade: selectedGrade });
      setIsAddModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Create failed:", error);
    }
  };

  // Handle update action
  const handleUpdate = async () => {
    try {
      await form.validateFields();
      modifyAssess(form.getFieldsValue());
      setIsEditModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  return (
    <div id="Assessment">
      <h2>Assessment</h2>
      <Card title="Assessment Data" bordered={false} style={{ margin: "20px" }}>
        <div className="assessment-page">
          {contextHolder}
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Input
                placeholder="Search"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                style={{ width: 200 }}
              />
            </Col>
            <Col>
              <Select
                placeholder="Select Column"
                value={selectedColumn}
                onChange={setSelectedColumn}
                style={{ width: 200 }}
                allowClear
              >
                {/* Dynamic Columns logic here */}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedGrade}
                onChange={handleGradeChange}
                style={{ marginBottom: 20 }}
                name="grade"
              >
                {gradeDivisions?.map((gra, index) => (
                  <Option key={index} value={gra.name}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col flex="auto">
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => setIsAddModalVisible(true)}
                >
                  Add Assessment
                </Button>
              </div>
            </Col>
          </Row>

          <DynamicTable
            data={assessments}
            loading={assLoading}
            // customColumns logic here
          />

          {editingAssessment && (
            <Modal
              title="Edit Assessment"
              open={isEditModalVisible}
              onOk={handleUpdate}
              onCancel={() => setIsEditModalVisible(false)}
            >
              <AssessmentForm
                form={form}
                editingAssessment={editingAssessment}
              />
            </Modal>
          )}

          <Modal
            title="Add Assessment"
            open={isAddModalVisible}
            onOk={handleCreate}
            onCancel={() => setIsAddModalVisible(false)}
          >
            <AssessmentForm form={form} gradeDivisions={gradeDivisions} />
          </Modal>
        </div>
      </Card>
    </div>
  );
};

export default Assessment;
