import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Spin,
  Descriptions,
  Row,
  Col,
  Modal,
  message,
  Typography,
} from "antd";
import usePost from "../../../hooks/usePost";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
const { Title, Paragraph } = Typography;

const ChapaPaymentForm = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const [verifyLoading, setVerifyLoading] = useState(false);
  const [verifyError, setVerifyError] = useState(null);
  const [verifyRes, setVerifyRes] = useState(null);

  const [paymentStatus, setPaymentStatus] = useState("unpaid");
  const { authRes, authLoading, authError, getAuth } = useAuth();

  // Fetch operation states
  const [paymentDetailsFetchLoading, setPaymentDetailsFetchLoading] =
    useState(false);

  const [paymentDetailsFetchError, setPaymentDetailsFetchError] =
    useState(null);

  const [paymentDetails, setPaymentDetails] = useState({});

  // Fetch payment details
  const { get: fetchPaymentDetails, reset } = useFetch(
    "/api/school/payment/payment-detail",
    {
      setLoading: setPaymentDetailsFetchLoading,
      setError: setPaymentDetailsFetchError,
      setData: setPaymentDetails,
    }
  );

  const { post: ConfirmPayment } = usePost("/api/school/payment/chapa/pay", {
    setLoading,
    setData,
    setError,
  });
  const { post: VerifyPayment } = usePost(
    "/api/school/payment/chapa/verify-payment",
    {
      setLoading: setVerifyLoading,
      setData: setVerifyRes,
      setError: setVerifyError,
    }
  );

  const handleConfirmPayment = () => {
    ConfirmPayment();
  };
  const handleVerifyPayment = () => {
    VerifyPayment();
  };

  useEffect(() => {
    fetchPaymentDetails();
    getAuth();
  }, []);

  useEffect(() => {
    if (loading) {
      message.info("Processing Payment...");
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      message.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (verifyError) {
      message.error(verifyError.message);
    }
  }, [verifyError]);

  useEffect(() => {
    if (data) {
      message.success("Payment initiated! You will be redirected...");
      if (data.url) window.location.href = data.url;
      reset();
    }
  }, [data]);

  useEffect(() => {
    if (verifyRes) {
      if (verifyRes?.message) message.success(verifyRes?.message);

      if (verifyRes?.status === "success") getAuth();
    }
  }, [verifyRes]);

  return (
    <Row justify="center" style={{ paddingTop: "50px" }}>
      <Col xs={24} sm={18} md={12}>
        {authRes?.schoolPaymentStatus === "unpaid" && (
          <Card
            title="Confirm Your Payment"
            bordered={false}
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Descriptions column={1} bordered size="middle">
              <Descriptions.Item label="School Name">
                {paymentDetails.schoolName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {paymentDetails.email}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                {paymentDetails.amount} ETB
              </Descriptions.Item>
              <Descriptions.Item label="Subscription">
                {paymentDetails.subscription}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Status">
                {paymentDetails.paymentStatus}
              </Descriptions.Item>
              <Descriptions.Item label="System Status">
                {paymentDetails.schoolStatus}
              </Descriptions.Item>
            </Descriptions>

            <Button
              type="primary"
              block
              onClick={handleConfirmPayment}
              loading={loading}
              style={{ marginTop: "20px", height: "40px", fontSize: "16px" }}
            >
              Confirm
            </Button>
          </Card>
        )}
        {authRes?.schoolPaymentStatus === "pending" && (
          <Card
            title="Verify Your Payment"
            bordered={false}
            style={{
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Descriptions column={1} bordered size="middle">
              <Descriptions.Item label="School Name">
                {paymentDetails.schoolName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {paymentDetails.email}
              </Descriptions.Item>
              <Descriptions.Item label="Amount">
                {paymentDetails.amount} ETB
              </Descriptions.Item>
              <Descriptions.Item label="Subscription">
                {paymentDetails.subscription}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Status">
                {paymentDetails.paymentStatus}
              </Descriptions.Item>
              <Descriptions.Item label="System Status">
                {paymentDetails.schoolStatus}
              </Descriptions.Item>
            </Descriptions>

            <Button
              type="primary"
              block
              onClick={handleVerifyPayment}
              loading={verifyLoading}
              style={{
                marginTop: "20px",
                height: "40px",
                fontSize: "16px",
                backgroundColor: "yellowgreen",
              }}
            >
              Verify
            </Button>
          </Card>
        )}
        {authRes?.schoolPaymentStatus === "payed" &&
          authRes?.isSchoolVerified === false && (
            <Card bordered={true} style={styles.card}>
              <Title level={3} style={styles.title}>
                🎉 Profile Created Successfully! 🎉
              </Title>
              <Paragraph style={styles.paragraph}>
                ✅ You have successfully created your school profile. <br />
                ⏳ Your system will be live within 24 hours. <br />
                🙏 Thank you for choosing our service!
              </Paragraph>
              <Button type="primary" onClick={() => navigate("/")}>
                Back To Home
              </Button>
            </Card>
          )}
      </Col>
    </Row>
  );
};

const styles = {
  card: {
    maxWidth: "400px",
    margin: "20px auto",
    textAlign: "center",
  },
  title: {
    color: "#52c41a",
  },
  paragraph: {
    fontSize: "16px",
    color: "#333",
  },
};

export default ChapaPaymentForm;
