import React from "react";
import { Form, Input, Button, Select } from "antd";

const { Option } = Select;

const AddGradeDivisionForm = ({ onAdd, onClose }) => {
  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const values = await form.validateFields();
    console.log(values);

    await onAdd(values);
    onClose();
    form.resetFields();
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        label="Division Name"
        name="name"
        rules={[
          { required: true, message: "Name is required" },
          { max: 100, message: "Name must not exceed 100 characters" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Division Code"
        name="code"
        rules={[
          { required: true, message: "Code is required" },
          { max: 50, message: "Code must not exceed 50 characters" },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Level"
        name="level"
        rules={[{ required: true, message: "Level is required" }]}
      >
        <Select placeholder="Select Level">
          <Option value="Pre-School">Pre-School</Option>
          <Option value="Primary">Primary</Option>
          <Option value="Secondary">Secondary</Option>
          <Option value="High School">High School</Option>
          <Option value="College">College</Option>
          <Option value="Undergraduate">University (Undergraduate)</Option>
          <Option value="Postgraduate">University (Postgraduate)</Option>
          <Option value="TVET">TVET</Option>
          <Option value="Vocational">Vocational</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Order"
        name="order"
        rules={[{ required: true, message: "Please input the term order!" }]}
      >
        <Input type="number" />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        rules={[
          { max: 255, message: "Description must not exceed 255 characters" },
        ]}
      >
        <Input />
      </Form.Item>
      <Button type="primary" onClick={handleSubmit}>
        Add Division
      </Button>
    </Form>
  );
};

export default AddGradeDivisionForm;
